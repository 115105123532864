import { Component, OnInit } from "@angular/core";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-organisation",
  templateUrl: "./organisation.component.html",
  styleUrls: ["./organisation.component.css"],
})
export class OrganisationComponent implements OnInit {
  constructor() {}

  navItems: [];
  noImgSrc = "../../../../assets/sfse-logo.png";
  caMembers = [];
  staffMembers = [];
  honoraryPresidents = [];
  sfseGreen = "#2B9192";
  pdf_id = null;

  ngOnInit() {
    window.scroll(0, 0);
    const caMembersURL = apiURL("ca_member", "lire-details", "");
    ajax(
      caMembersURL,
      "GET",
      null,
      (res) => {
        let response: any = res;

        response = JSON.parse(response);

        this.caMembers = response.records.map((member) => {
          member.img =
            (member.img && "data:image/jpg;base64," + member.img) ||
            "../../../../assets/sfse-logo.png";
          return member;
        });
      },
      false
    );

    const staffMembersURL = apiURL("staff_member", "lire-details", "");
    ajax(
      staffMembersURL,
      "GET",
      null,
      (res) => {
        let response: any = res;

        response = JSON.parse(response);
        this.staffMembers = response.records.map((member) => {
          member.img =
            (member.img && "data:image/jpg;base64," + member.img) ||
            "../../../../assets/sfse-logo.png";
          return member;
        });
      },
      false
    );

    const honoraryPresidentsURL = apiURL(
      "honorary_president",
      "lire-details",
      ""
    );
    ajax(
      honoraryPresidentsURL,
      "GET",
      null,
      (res) => {
        let response: any = res;

        response = JSON.parse(response);
        this.honoraryPresidents = response.records.map((member) => {
          member.img =
            (member.img && "data:image/jpg;base64," + member.img) ||
            "../../../../assets/sfse-logo.png";
          return member;
        });
      },
      false
    );
  }
}
