import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ERS_URL_WITH_BLANK_URI } from "../../../providers/global";
import { ajax, apiURL } from "../../../providers/helpers";
import { getStoredUser } from "../../../providers/user-service";

@Component({
  selector: "app-environment-journal",
  templateUrl: "./environment-journal.component.html",
  styleUrls: ["./environment-journal.component.css"],
})
export class EnvironmentJournalComponent implements OnInit {
  ers_url = ERS_URL_WITH_BLANK_URI;
  loading = false;
  displayAside = false;
  selectedIndex = 0;
  selectedItemIndex = 0;

  constructor(private activatedRoute: ActivatedRoute) {}

  revues: [];

  ngOnInit() {
    this.activatedRoute.params.subscribe((data) => {
      this.selectedIndex = data.selectedIndex;
      this.selectedItemIndex = data.selectedItemIndex;
      this.displayAside = false;
      window.setTimeout(() => {
        this.displayAside = true;
      }, 10);
    });

    window.scroll(0, 0);
    this.loading = true;
    const user = getStoredUser();
    if (user.role_id != 3) {
      this.ers_url = "https://www.jle.com";
    }
    const url =
      "https://www.jle.com/api/v1/revue/archives.mason?code_revue=ers&lang=fr";
    // const url = 'https://www.jle.com//api/v1/revues.mason?code_revue=ers&lang=fr';

    const url2 = apiURL("-php-request", "request", "");
    ajax(
      url2,
      "POST",
      { url },
      (res) => {
        const response = JSON.parse(res).records;
        if (!response) {
          return;
        }
        this.revues = JSON.parse(response)
          .data.slice(0, 12)
          .map((revue) => {
            revue.redirectUrl = this.ers_url + revue.href;
            // console.log(revue.redirectUrl);
            return revue;
          });

        // console.log(JSON.parse(response).data);
        // this.revues = JSON.parse(response).data.sort((a,b) => {
        //   return a.date_parution > b.date_parution;
        // }).slice(0, 12);
        this.loading = false;
      },
      false
    );
  }
}
