import { Component, OnInit } from "@angular/core";
// import {  } from '../../../providers/global';
import { ActivatedRoute } from "@angular/router";
import { ajax, apiURL } from "../../../../providers/helpers";

@Component({
  selector: "app-my-think-section-docs",
  templateUrl: "./my-think-section-docs.component.html",
  styleUrls: ["./my-think-section-docs.component.css"],
})
export class MyThinkSectionDocsComponent implements OnInit {
  //_pdf_name = _PDF_NAME;

  thinkSectionPdfs: any;
  thinkSectionHasNoDocs = false;
  pdf_id = "";

  popupText = "";
  showPopup = false;

  constructor(private router: ActivatedRoute) {}

  closePopupAndWindow() {
    this.showPopup = null;
  }

  // showPdf() {
  //   const url = apiURL('pdf', 'read_one_id', '');

  //   ajax(url, 'POST', { name: _PDF_NAME }, res => {
  //     let response: any = res;
  //     response = JSON.parse(response);
  //     this.pdf_id = response.records.id;
  //   }, false);
  // }

  loadThinkSectionPdfs(sectionId) {
    const url = apiURL("think_section", "read_pdfs", sectionId);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.thinkSectionPdfs = JSON.parse(res).records;
        // console.log(this.thinkSectionPdfs);
        if (
          this.loadThinkSectionPdfs.length === 1 &&
          !this.thinkSectionPdfs[0].name &&
          !this.thinkSectionPdfs[0].pdf_id
        ) {
          this.thinkSectionHasNoDocs = true;
        } else {
          this.thinkSectionHasNoDocs = false;
        }
      },
      false
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.router.params.subscribe((data) => {
      this.loadThinkSectionPdfs(data.id);
    });
  }
}
