import { Component, OnInit } from '@angular/core';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { apiURL, ajax } from '../../../providers/helpers';
import { ActivatedRoute } from '@angular/router';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { getStoredUser } from 'src/app/providers/user-service';


@Component({
  selector: 'app-awards-list',
  templateUrl: './awards-list.component.html',
  styleUrls: ['./awards-list.component.css']
})
export class AwardsListComponent implements OnInit {

  faClone = faClone;
  congress : any = {};
  congress_award_titles = [];
  storage;
  pdf_id;
  user: any;

  constructor(
    private congressService: CongressService, 
    private activatedRoute: ActivatedRoute) {}

  getDocs() {
    // console.log(this.congress.id);

    const url = apiURL('congress_award', 'read_by_congress', this.congress.id);
    ajax(url, 'GET', null, (res: any) => {
      const awards = JSON.parse(res).records;
      // console.log(awards);
      awards.forEach((award, index) => {
        if (!awards[index - 1] || awards[index-1].award_title !== award.award_title) {
          this.congress_award_titles.push({
            title: award.award_title,
            awards: [] 
          });
        }
        this.congress_award_titles[this.congress_award_titles.length - 1].awards.push(award);
      });
    }, false);
  }

  loadCongress(id) {
    window.scroll(0,0); 
    const url = apiURL('congress', 'read_one', id);
    ajax(url, 'GET', null, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;}
      this.congress = response.records; 
      this.congress.img_data  =  this.congress.img_data ? `url("data:image/jpg;base64,${this.congress.img_data}")` : 'url("../../../../assets/test/event-2.jpg")';
      this.congress.img_data2  =  this.congress.img_data2 ? 'data:image/jpg;base64,' + this.congress.img_data2 : '../../../../assets/test/event-1.jpg';
      this.storage.setItem('congress', this.congress);
      this.congress = this.congress;
      this.getDocs();
    }, false)
  }

  ngOnInit() {
    this.user = getStoredUser();
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('prixpasses', data.id).then((data: any) => {
        this.congress = data.congress;
        this.getDocs();
      }).catch(err => {
        // console.log(err);
      });
    });
  }

}
