import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-soutenir',
  templateUrl: './soutenir.component.html',
  styleUrls: ['./soutenir.component.css']
})
export class SoutenirComponent implements OnInit {
  navItems: [];
  constructor(private router: Router) { }

  goTo(page) {
    switch (page) {
      case 'adhesion' :
        this.router.navigate(['/espace_perso/accueil']);
        break;
      case 'donnation' :
        this.router.navigate(['/espace_perso/nous_aider']);
        break;
      case 'contact' :
        this.router.navigate(['/presentation/contact']);
        break;
    }
  }
  
  ngOnInit() {
    window.scroll(0,0);
  }

}
