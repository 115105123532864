import { Component, OnInit } from '@angular/core';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {

  congress : any = {};
  pdf_id;
  sideNav2;
  user: any = {};


  constructor(
    private congressService: CongressService, 
    private activatedRoute: ActivatedRoute) {}

  showModalities() {
    this.pdf_id = this.congress.terms_pdf_id;
  }

  

  ngOnInit() {
    window.scroll(0,0); 
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('appelacommunication', data.id).then((data: any) => {
        this.user     = data.user;

        this.congress = data.congress;
        this.congress.com_appeal_limit = this.congress.com_appeal_limit.split("-").reverse().join("-");
        this.sideNav2 = data.sideNav2;  
      }).catch(err => {
        // console.log(err);
      });
    });
  }

}
