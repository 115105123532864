import { /*HostListener,*/ Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { MONTHS } from "src/app/providers/global";
import { ajax, apiURL } from "../../providers/helpers";
import { NAV_URLS } from "../../providers/NavData";
import { PdfGeneratorService } from "../../providers/pdf-generator.service";
import { getStoredUser } from "../../providers/user-service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  // @HostListener('window:resize', ['$event'])
  // handleKeyDown(event: KeyboardEvent) {
  //   const target : any = event.target;
  //   this.slideHeight = target.innerWidth / 3.5 ;
  //   this.highlightedItemHeight = target.innerWidth / 6 ;
  // }
  loading = false;

  user: any = {};

  faSearch = faSearch;
  faCalendarAlt = faCalendarAlt;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faMapMarkerAlt = faMapMarkerAlt;
  canSlide = true;
  currentSlideIndex = 0;
  slideHeight;
  sliderInterval = null;
  highlightedItemHeight;
  sliderItems = [];
  slideDots = [];
  displayLoginPopup = false;

  highlightedActus = [];
  highlightedEvents = [];
  events = [];
  popupIsFocused;

  showPopup = false;
  popupText = "";

  pdf_id = "";

  newsLetterHandler() {
    if (this.user.id) {
      this.goToProfile();
    } else {
      this.popupText =
        "La demande d’inscription à notre lettre d’information se fait dans votre espace personnel. Si vous n’avez pas de compte, vous pouvez en créer un en moins d’une minute";
      this.showPopup = true;
      this.displayLoginPopup = true;
      this.popupIsFocused = true;
    }
  }

  goToProfile() {
    window.scroll(0, 0);
    this.router.navigate(["/espace_perso/profil"]);
  }
  goToManifestations() {
    window.scroll(0, 0);
    this.router.navigate(["articles/ressources/event/eventlire-details/0/0/4"]);
  }

  goToNosActions() {
    window.scroll(0, 0);
    this.router.navigate(["/actions"]);
  }

  goToRessourcesMain() {
    window.scroll(0, 0);
    this.router.navigate(["/ressources"]);
  }

  goToMembership() {
    window.scroll(0, 0);
    this.router.navigate(["/espace_perso/accueil"]);
  }

  goToQuiSommesNous() {
    window.scroll(0, 0);
    this.router.navigate(["/presentation/quisommesnous"]);
  }

  goToPostDetails(table_name, id, element) {
    if (table_name === "congress") {
      this.router.navigate(["/congres/presentation/null"]);
      return;
    }

    let navUrl =
      table_name === "event"
        ? NAV_URLS[table_name][element.event_type]
        : NAV_URLS[table_name];
    const navUrl_ = navUrl.split("/");
    navUrl_.splice(2, 0, element.title.split(" ").join("-"));
    navUrl = navUrl_.join("/");

    this.router.navigate([`${navUrl}/${id}`]);
  }

  sponsored = { "background-color": "var(--sfse-blue)", color: "white" };

  notSponsored = { "background-color": "white", color: "black" };

  firstSlideMarginLeft = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private pdfGeneratorService: PdfGeneratorService
  ) {}

  launchInfiniteSlide() {
    if (this.sliderInterval === null) {
      this.sliderInterval = window.setInterval(() => {
        this.slideRight();
      }, 4000);
    }
  }

  stopInfiniteSlide() {
    window.clearInterval(this.sliderInterval);
    this.sliderInterval = null;
  }

  slideLeft() {
    if (!this.canSlide) {
      return;
    }
    const length = this.sliderItems.length;
    if (this.canSlide && this.currentSlideIndex === 0) {
      this.sliderItems.splice(0, 0, this.sliderItems[length - 1]);
      this.firstSlideMarginLeft -= 100;
      this.currentSlideIndex = length;
    }

    this.canSlide = false;
    const finalMarginLeft = this.firstSlideMarginLeft + 100;
    const interval = window.setInterval(() => {
      this.firstSlideMarginLeft += 4;
      if (this.firstSlideMarginLeft >= finalMarginLeft) {
        clearInterval(interval);
        this.canSlide = true;
        this.currentSlideIndex--;
        if (this.currentSlideIndex === length - 1) {
          this.sliderItems.splice(0, 1);
          this.firstSlideMarginLeft = -100 * (length - 1);
        }
      }
    }, 20);
  }

  slideRight() {
    if (!this.canSlide) {
      return;
    }
    if (this.currentSlideIndex === this.sliderItems.length - 1) {
      this.sliderItems.push(this.sliderItems[0]);
      this.currentSlideIndex = -1;
    }
    this.canSlide = false;
    const finalMarginLeft = this.firstSlideMarginLeft - 100;
    const interval = window.setInterval(() => {
      this.firstSlideMarginLeft -= 4;
      if (this.firstSlideMarginLeft <= finalMarginLeft) {
        clearInterval(interval);
        this.canSlide = true;
        this.currentSlideIndex++;
        if (this.currentSlideIndex === 0) {
          this.firstSlideMarginLeft = 0;
          this.sliderItems.splice(this.sliderItems.length - 1, 1);
        }
      }
    }, 20);
  }

  slideTo(index) {
    this.currentSlideIndex = index;
    this.firstSlideMarginLeft = index * -100;
  }

  openLink(link) {
    window.location.href = link;
  }

  generatePdfFiscalAdhesionPhysique(
    user_firstname,
    user_name,
    billing_number,
    now
  ) {
    const infos = {
      userFirstname: user_firstname,
      userName: user_name,
      reciepeOrderNumber: billing_number,
      date_paiement: now,
    };

    this.pdfGeneratorService.generatePdf("fiscal-adhesion-physique", infos);
  }

  generatePdfFactureAdhesionPhysiqueAcquitee(
    fullAdress,
    fullName,
    billingNumber,
    pricing_name,
    amount_inscription,
    now
  ) {
    const infos = {
      address: [fullAdress],
      userName: fullName,
      billNumber: billingNumber,
      pricing: { name: `Tarif ${pricing_name}`, val: amount_inscription },
      ers: false,
      datePaiement: now,
    };

    this.pdfGeneratorService.generatePdf(
      "facture-adhesion-physique-acquittee",
      infos
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    const pdfId = new URLSearchParams(window.location.search).get("pdf");
    if (pdfId) {
      this.pdf_id = pdfId;
    }
    const paiement_statut = new URLSearchParams(window.location.search).get(
      "paiement_statut"
    );
    if (paiement_statut === "ok") {
      this.showPopup = true;
      this.popupText =
        "<h2>Félicitation !</h2> <p>Votre transaction a bien été effectuée ! Si vous venez d'adhérer à la SFSE, vous pouvez vous déconnecter puis vous connecter de nouveau pour accéder aux nouvelles rubriques de l'espace adhérent.</p>";
    } else if (paiement_statut === "erreur") {
      this.showPopup = true;
      this.popupText =
        "<h2>Erreur transaction</h2> <p>Une erreur semble être survenue lors de votre tentative de paiement. Pour plus d'information, vous pouvez contacter la SFSE.</p>";
    }

    // console.log(paiement_statut);

    this.loading = true;
    this.user = getStoredUser();
    let loadingIsOver = false;

    const sliderHighlightedUrl = apiURL(
      "highlighted_slider",
      "lire-details",
      ""
    );
    ajax(
      sliderHighlightedUrl,
      "GET",
      null,
      (res: any) => {
        let items = JSON.parse(res).records;
        this.sliderItems = items.map((item) => {
          // console.log(item);
          this.slideDots.push(null);
          item.img_data = item.img_data
            ? 'url("' + "data:image/jpg;base64," + item.img_data + '")'
            : "url('../../../assets/sfse-logo.png')";

          return item;
        });
        loadingIsOver ? (this.loading = false) : (loadingIsOver = true);
      },
      false,
      (err) => {
        err;
        this.loading = false;
      }
    );

    const url = apiURL("highlighted", "read_items", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        const highlightedActus = [];
        JSON.parse(res).records.forEach((item) => {
          item.description_short = item.description_short.replace(
            /&amp;/g,
            "&"
          );
          item.description_short = item.description_short.replace(
            /&quot;/g,
            '"'
          );
          if (!item.img_data && item.table_name === "event") {
            item.img_data =
              "url('../../../assets/pictos/sfse-agenda-hover.png')";
          } else {
            item.img_data = item.img_data
              ? 'url("' + "data:image/jpg;base64," + item.img_data + '")'
              : "url('../../../assets/pictos/sfse-" +
                item.table_name +
                "-hover.png')";
          }

          if (item.table_name === "event") {
            const splitted = item.debut.split("-");
            item.dayNum = splitted[2];
            item.month = MONTHS[parseInt(splitted[1])];
            this.highlightedEvents.push(item);
          } else {
            if (
              ["publication_sfse", "ri", "contribution_sfse"].indexOf(
                item.table_name
              ) > -1
            ) {
              item.color = "var(--sfse-pink-menu)";
            } else {
              item.color = "var(--sfse-blue-menu)";
            }
            highlightedActus.push(item);
          }
        });
        this.highlightedActus = highlightedActus.sort(
          (a, b) => b.highlighted_id - a.highlighted_id
        );
        loadingIsOver ? (this.loading = false) : (loadingIsOver = true);
      },
      false,
      (err) => {
        this.loading = false;
      }
    );

    window.setTimeout(() => {
      this.launchInfiniteSlide();
    }, 3000);

    // this.slideHeight = window.innerWidth / 3.5 ;
    // this.highlightedItemHeight = window.innerWidth / 6 ;
  }
}
