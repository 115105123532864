import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { Subject } from 'rxjs';
    
@Component({
  selector: 'app-filtered-by-thematics',
  templateUrl: './filtered-by-thematics.component.html',
  styleUrls: ['./filtered-by-thematics.component.css']
})
export class FilteredByThematicsComponent implements OnInit {

  eventsSubject: Subject<void> = new Subject<void>();
  thematic_subs = [];
  selectedThematics = <any>[];


  tables_ = [
    { title: 'Appels à communication',  name: 'event',          results: [],  method: 'lire-details', type: 'comm_appeal'},
    { title: 'Actes de valorisation',   name: 'event',          results: [],  method: 'lire-details', type: 'val_act'},
    { title: 'Manifestations',          name: 'event',          results: [],  method: 'lire-details', type: 'event'},
    { title: 'Formations',              name: 'formation',      results: [],  method: 'lire'},
    { title: 'Appels à projets',        name: 'project_appeal', results: [],  method: 'lire'},
    { title: 'Annonces emploi',         name: 'job_announce',   results: [],  method: 'lire'},
    { title: 'Politiques publiques',    name: 'public_politic', results: [],  method: 'lire'},
    { title: 'Outils web',              name: 'web_tool',       results: [],  method: 'lire'},
    { title: 'Informations divers',     name: 'infos_divers',   results: [],  method: 'lire'}
];
tables = [];
thematics_ = <any>{};

  constructor() { }

  search() {
    this.selectedThematics = [];
    this.thematic_subs.forEach(thematic_sub => {
      if(thematic_sub.checked) {
        this.selectedThematics.push(thematic_sub.id);
      }
    });

    const thematic_ids = this.selectedThematics.join(',');

    this.tables = this.tables_.map((table, i) => {
      const table_:any = table;
      table_.index = i;
      table_.thematic_id = thematic_ids;
      return table_;
    });
    this.eventsSubject.next();
  }

  loadItems(evt) {
    this.tables[evt.index].results = [];
    evt.records.forEach(item => {
      if (item.img_data) {
        item.img_data = 'data:image/jpg;base64,' + item.img_data;
      }

      this.tables[evt.index].results.push(item);
    });
  }

  ngOnInit() {
    const thematicSubsURL = apiURL('thematic_sub', 'lire', '');
    ajax(thematicSubsURL, 'GET', null, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      const thematic_subs = response.records;
      this.thematic_subs = thematic_subs.map(thematic_sub => {
       thematic_sub.checked = false;
       return thematic_sub;
      });
    }, false);  
  }

}
