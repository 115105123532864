class Field {
    type: string;
    required: boolean;
    text: string;
    constructor(type, required?, text?) {
        this.type = type;
        this.required = required || false;
        this.text = text || '';
    }
}

class InputCheckbox extends Field {
    model: string;
    placeholder: string;
    constructor(type, model, text, required?, placeholder?) {
        super(type, required, text);
        this.model = model;
        this.placeholder = placeholder || this.text;
    }
}

class TextAreaDate extends Field {
    model: string;
    placeholder: string;
    constructor(type, model, text, placeholder, required?) {
        super(type, required, text);
        this.model = model;
        this.placeholder = placeholder;
    }
}


class Select extends Field {
    model: string;
    table_name: string;
    options: any[];
    method = 'lire';
    constructor(model, text, table_name, required?) {
        super('select', required, text);
        this.model = model;
        this.table_name = table_name;
    }
}

class SelectMulti extends Field {
    table1: string;
    table2: string;
    placeholder: string;
    constructor(text, table1, table2, placeholder, required?) {
      super('selectMulti', required, text);
      this.table1 = table1; 
      this.table2 = table2; 
      this.placeholder = placeholder; 
    }
}

class Country extends Field {
    options: any[];
    method: string;
    model = 'country_id';
    constructor(required?, text?) {
        super('country', required, text || 'Pays');
        this.method = 'lire';
    }
}

class Radio extends Field {
    model: string;
    options: { val: string, text: string }[];
    constructor(model, text, options, required?) {
        super('radio', required, text);
        this.model = model;
        this.options = options;
    }
}

class ImgPdf extends Field {
    model: string;
    constructor(model, required?, text?) {
        super(model, required, text);
        this.model = model;
    }
}

export const FIELD_COSTRUCTORS = { InputCheckbox, TextAreaDate, Radio, Select, ImgPdf, Country, SelectMulti };