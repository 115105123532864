import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-presentations-list',
  templateUrl: './presentations-list.component.html',
  styleUrls: ['./presentations-list.component.css']
})
export class PresentationsListComponent implements OnInit {
  congress_session_titles = [];
  congress : any = {};
  storage;
  pdf_id;

  constructor(
    private congressService: CongressService, 
    private activatedRoute: ActivatedRoute) {}
  
  getDocs() {
    
    const url = apiURL('congress_pres', 'read_by_congress', this.congress.id);
    ajax(url, 'GET', null, (res: any) => {
     
      const presentations = JSON.parse(res).records;
      presentations.forEach((pres, index) => {
        if (!presentations[index - 1] || presentations[index-1].session_title_name !== pres.session_title_name) {
          this.congress_session_titles.push({
            name: pres.session_title_name,
            presentations: [] 
          });
        }
        this.congress_session_titles[this.congress_session_titles.length - 1].presentations.push(pres);
      });
    }, false);
  }

  
  ngOnInit() {
    window.scroll(0,0);

    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('presentations', data.id).then((data: any) => {
        this.congress = data.congress;
        this.getDocs(); 
      }).catch(err => {
        // console.log(err);
      });
    });
  }
  
}


    /*
    this.congress_session_titles = [
     {
       name: 'Titre 1',
       presentations : [
         {
           description: '<strong>Coucou ça va ??</strong> On est là',
           pdf_id: 2
         },
         {
           description: '<span style="color: red">C\'est top</span> On est là',
           pdf_id: 3
         },
       ]

     },
     {
       name: 'Titre 2',
       presentations : [
         {
           description: '<strong>Coucou ça va ??</strong> On est là',
           pdf_id: 2
         },
         {
           description: '<span style="color: #8e6a">C\'est top</span> On est là',
           pdf_id: 3
         },
       ]

     }
   ]; 
   */   