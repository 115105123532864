import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SocialShareService {

  currentUrl: string;
  title: string;
  description: string;

  constructor(private meta: Meta) { }

  updateMetas(title, description) : Promise<string> {
    this.title        = title;
    this.description  = description;

    return new Promise(resolve => {
      this.currentUrl = encodeURI(window.location.href);

      
      // this.meta.removeTag("property='og:url'");
      this.meta.removeTag("property='og:title'");
      this.meta.removeTag("property='og:description'");
      //this.meta.removeTag("property='og:image'");
      
      this.meta.addTags(
        [
          { property:"og:url", content: this.currentUrl },
          { property:"og:title", content: title },
          { property:"og:description", content: description }        
        ]
      );
      
      resolve('success');
    });
    
  }
  
  fbShare() {
    this.currentUrl = encodeURI(window.location.href);
    const dialogFeedUrl = `https://www.facebook.com/dialog/feed?app_id=3571545426242615&display=popup&link=${this.currentUrl}&redirect_uri=${window.location.href}&description=${this.description}`;
    const url = 'https://www.facebook.com/sharer/sharer.php?u=' + this.currentUrl + '?app_id=3571545426242615&quote=' + this.title  + ' : ' + this.description;
    (url);
    window.open(url, 'facebook-popup', 'height=450,width=600');
   
  }

  linkedinShare() {
    this.currentUrl = encodeURI(window.location.href);
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${this.currentUrl}`;
    window.open(url, 'linkedin-popup', 'height=450,width=600');
  }

  twitteShare(description) {
    this.currentUrl = encodeURI(window.location.href);
    const url = `http://twitter.com/share?url=${this.currentUrl}&text=${description}`;
    window.open(url, 'linkedin-popup', 'height=450,width=600');
  }
}
