import { Component, OnInit } from '@angular/core';
import { AVANTAGES_ADHESION_MORAL_PDF_NAME, PRICINGS_ADHESION_MORAL_PDF_NAME, PROCEDURE_ADHESION_MORAL } from '../../../providers/global';
import { apiURL, ajax } from '../../../providers/helpers';

@Component({
  selector: 'app-adhesion-morale',
  templateUrl: './adhesion-morale.component.html',
  styleUrls: ['./adhesion-morale.component.css']
})
export class AdhesionMoraleComponent implements OnInit {

  pdf_id;

  constructor() { }

  setPdfName(pdfName) {
    switch(pdfName) {
      case "procedure" : return PROCEDURE_ADHESION_MORAL;break;
      case "avantages" : return AVANTAGES_ADHESION_MORAL_PDF_NAME;break;
      case "pricings" : return PRICINGS_ADHESION_MORAL_PDF_NAME;
    }
  }

  showPdf(pdfName) {
    const name = this.setPdfName(pdfName);
    const url = apiURL('pdf', 'read_one_id', '');

    ajax(url, 'POST', {name}, res => {
      let response: any = res;
      response = JSON.parse(response);
      this.pdf_id = response.records.id;
    }, false);
  }

  ngOnInit() {
      window.scroll(0,0);
  }

}
