import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-doc-found',
  templateUrl: './doc-found.component.html',
  styleUrls: ['./doc-found.component.css']
})
export class DocFoundComponent implements OnInit {

  navItem = { icon: 'acts' };
  congress : any = {};
  docs: any[] = [];
  sideNav2 = null;
  user: any = {};

  constructor(
    private congressService: CongressService, 
    private activatedRoute: ActivatedRoute) {}


  getDocs() {
  
    const url = apiURL('congress_doc', 'lire', this.congress.id);
    ajax(url, 'GET', null, (res: any) => {
     
      this.docs = JSON.parse(res).records;
    }, false);
  }

  ngOnInit() {
    window.scroll(0,0); 
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('fondsdocumentaire', data.id).then((data: any) => {
        this.user     = data.user;
        this.congress = data.congress;
        this.sideNav2 = data.sideNav2;  
        this.getDocs();
      }).catch(err => {
        // console.log(err);
      });
    });
  }

}
