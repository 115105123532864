import { Component, OnInit } from '@angular/core';

import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {

  congress: any = {};
  pdf_id;
  sideNav2;
  user: any = {};


  constructor(
    private congressService: CongressService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    window.scroll(0,0); 
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('organisation', data.id).then((data: any) => {
        this.user     = data.user;
        this.congress = data.congress;
        this.sideNav2 = data.sideNav2;  
        // console.log(this.congress);
      }).catch(err => {
        // console.log(err);
      });
    });
  }

}
