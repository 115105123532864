export class NavItem {
    url : string;
    title : string;
    icon : string;
    fullTitle: string;
    visible_by: string;
    id: number;

    constructor(id, url, title, icon?, fullTitle?, visible_by?) {
        this.id = id;
        this.url = url;
        this.title = title;
        this.icon = icon;
        this.fullTitle = fullTitle;
        this.visible_by = visible_by;
    }
}
