import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { Router } from '@angular/router';
import { NavData } from '../../../providers/NavData';

@Component({
  selector: 'app-partenaires',
  templateUrl: './partenaires.component.html',
  styleUrls: ['./partenaires.component.css']
})
export class PartenairesComponent implements OnInit {

  constructor(private router: Router) {}

  navItems: [];
  noImgSrc = '../../../../assets/sfse-logo.png';
  partners = [];
  pdf_id = null;
  canLoadMore: boolean;

  goToCongressPartners() {
    window.sessionStorage.currentNavItemIndex = 3;
    this.router.navigate(['/congres/partenaires/null']);
  }

  readAllPartners() {
    const partnerUrl = apiURL('partner', 'lire-details', '');
    ajax(partnerUrl, 'POST', {}, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      const restOfPartners = response.records.map((partner, index) => {
        partner.index = index;
        partner.img = partner.img && 'data:image/jpg;base64,' + partner.img || this.noImgSrc;
        partner.hovered = null;
        return partner;
      });

      this.partners = [...this.partners, ...restOfPartners]
      this.canLoadMore = false;
    }, false);
  }
  ngOnInit() {
    window.scroll(0,0);
    const partnerUrl = apiURL('partner', 'lire-details', '');
    ajax(partnerUrl, 'POST', {limitTo4: true}, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      const partners = response.records;
      this.partners = partners.map((partner, index) => {
        partner.index = index;
        partner.img = partner.img && 'data:image/jpg;base64,' + partner.img || this.noImgSrc;
        partner.hovered = null;
        return partner;
      });
      this.canLoadMore = true;
    }, false);
  }

}
