import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
// import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { ENV } from './providers/helpers';


// declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'SFSE';
  currentNavItemIndex: number;
  env = ENV;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private titleService: Title) {

    if (this.env == "prod") {
      /** START : Code to Track Page View using gtag.js */
      // this.router.events.pipe(
      //   filter(event => event instanceof NavigationEnd)
      // ).subscribe((event: NavigationEnd) => {
      //   gtag('event', 'page_view', {
      //     page_path: event.urlAfterRedirects
      //   })
      // })
      /** END : Code to Track Page View  using gtag.js */

      //Add dynamic title for selected pages - Start
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          var title = this.getTitle(router.routerState, router.routerState.root).join(' > ');
          titleService.setTitle(title);
        }
      });
      //Add dynamic title for selected pages - End
    }
  }

  // collect that title data properties from all child routes
  // there might be a better way but this worked for me
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  onActivate() {
    if (window.sessionStorage.currentNavItemIndex) {
      this.currentNavItemIndex = window.sessionStorage.currentNavItemIndex;
      window.sessionStorage.currentNavItemIndex = null;
    }
  }

}
