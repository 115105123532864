import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  navItem = {icon: 'acts'};

  partners;
  congress: any = {};
  sideNav2 = null;
  user: any = {};

  constructor(
    private congressService: CongressService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute) {}

  getPartners() {
    const url = apiURL('congress_partner', 'lire-details', this.congress.id);
    ajax(url, 'GET', null, (res: any) => {
      // console.log(res);
      this.partners = JSON.parse(res).records.map(partner => {
        if(partner.img_data) {
          partner.img_data = 'data:image/jpg;base64,' + partner.img_data;
        }
        return partner;
      });

    }, false);
  }

  ngOnInit() {
    window.scroll(0,0);
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('partenaires', data.id).then((data: any) => {
        this.congress = data.congress;
        this.sideNav2 = data.sideNav2; 
        this.user     = data.user;
        this.getPartners(); 
      }).catch(err => {
        // console.log(err);
      });
    });
  }
}
