import { environment } from "src/environments/environment";

export const ENV = "prod";
export const GLOBALS = {
  dev: {
    API_URL: "https://test-esfs-api.sfse.org", //  'https://dc38.digital-college17.net' //
  },
  prod: {
    API_URL: "https://api.sfse.org",
  },
  //'https://www.sfse-api.bruno-heritier.com/sfse/api', //  'bruno-heritier.com/sfse/api'//
  API_CREDENTIALS: "toto",
  MONTHS: [
    "",
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  STORAGE_ID: "sfse_bruno_heritier_developpeurweb",
};

export const CGU_PDF_ID = 9;

export const COUNTRIES_WITH_REGIONS = {
  "21": "Belgique",
  "38": "Canada",
  "73": "France (Métropolitaine)",
  "74": "France (Outre-mer)",
  "213": "Suisse",
};

export const apiURL = (object, methodName, id) => {
  let method;
  switch (methodName) {
    case "lire-details":
      method = "read_with_data";
      break;
    case "lire":
      method = "read";
      break;

    default:
      method = methodName;
  }
  return `${GLOBALS[ENV].API_URL}/-${object}/${method}.php?secure_key=${GLOBALS.API_CREDENTIALS}&id=${id}&local_env=${environment.local_env}`;
};

export const ajax = (url, method, data, success, isImage, error?) => {
  const req = new XMLHttpRequest();

  req.addEventListener(
    "load",
    () => {
      if (req.status >= 200 && req.status < 400) {
        // console.log(req);
        // const reponse = JSON.parse(req.responseText);
        success(req.response);
      } else {
        // alert('une erreur est survenue');
        // console.log('Une erreur est survenue. Statut de la requête  : ' + req.status, url);
        // console.log(req.response);
        if (error) {
          error(req.response);
        }
      }
    },
    true
  );

  req.open(method, url);

  req.addEventListener("error", (e) => {
    // console.log(e);
    alert("une erreur est survenue");
  });

  if (data && !isImage) {
    data = JSON.stringify(data);
  }

  req.send(data);
};

export const timestampToDate = (timestamp, getTime?) => {
  const date_ = new Date(Number(timestamp));
  let day: any = date_.getDate();
  if (day < 10) day = "0" + day;
  let month: any = date_.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let output = day + "/" + month + "/" + date_.getFullYear();

  if (getTime) {
    let h: any = date_.getHours();
    if (h < 10) h = "0" + h;
    let m: any = date_.getMinutes();
    if (m < 10) m = "0" + m;
    let s: any = date_.getSeconds();
    if (s < 10) s = "0" + s;
    output += `-${h}h${m}min${s}s`;
  }

  return output;
};

export const resizeImage = (e, fileName, mimeType, quality, width) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = e.target.result;

    img.onload = () => {
      const ratio = img.width / img.height;
      const height = width / ratio;
      const elem = document.createElement("canvas");
      elem.width = width;
      elem.height = height;
      const ctx = elem.getContext("2d");
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, width, height);
      ctx.canvas.toBlob(
        (blob) => {
          const file = new File([blob], fileName, {
            type: mimeType,
            lastModified: Date.now(),
          });
          resolve(file);
        },
        mimeType,
        quality
      );
    };
  });
};

export const toDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", file);
    xhr.send();
  });
};

export const num2letters = (number) => {
  if (!(number <= 999999 && number >= 0)) {
    alert("erreur");
    return;
  }

  let result = "";

  if (number > 999) {
    let thousands = Math.floor(number / 1000);
    if (thousands > 1) {
      result += to999(thousands) + " mille ";
    } else {
      result += "Mille ";
    }
    number = number % 1000;
  }

  result += to999(number);

  return result;
};

const unitsLetters = [
  "zero",
  "un",
  "deux",
  "trois",
  "quatre",
  "cinq",
  "six",
  "sept",
  "huit",
  "neuf",
  "dix",
  "onze",
  "douze",
  "treize",
  "quatorze",
  "quinze",
  "seize",
];
const dozensLetters = [
  "",
  "dix",
  "vingt",
  "trente",
  "quarante",
  "cinquante",
  "soixante",
  "soixante-dix",
  "quatre-vingt",
  "quatre-vingt-dix",
];
const to999 = (number) => {
  if (number === 0) {
    return "zéro";
  }

  let result = "";
  let numberSuffix = number % 100;

  if (number > 99) {
    let hundreds = (number - (number % 100)) / 100;
    number -= hundreds * 100;

    if (hundreds > 1) {
      result += unitsLetters[hundreds] + " ";
    }

    result += "cent";
    if (numberSuffix > 0) {
      result += "-";
    }
  }

  if (number > 0 && number < 10) {
    result += unitsLetters[number];
  } else if (number >= 10) {
    let dozens = (number - (number % 10)) / 10;
    number -= dozens * 10;

    if (number === 0) {
      result += dozensLetters[dozens];
    } else if (dozens == 1 || dozens == 7 || dozens == 9) {
      if (number < 7 && number > 0) {
        if (dozens == 1) {
          result += unitsLetters[10 + number];
        } else {
          result += dozensLetters[dozens - 1];
          if (dozens * 10 + number === 71) {
            result += "-et-onze";
          } else {
            result += "-" + unitsLetters[10 + number];
          }
        }
      } else {
        result += dozensLetters[dozens] + "-";
        result += unitsLetters[number];
      }
    } else {
      result += dozensLetters[dozens];
      if (number === 1) {
        if (dozens !== 8) {
          result += "-et";
        }
        result += "-un";
      } else if (number > 0) {
        result += "-" + unitsLetters[number];
      }
    }
  }

  return result;
};

export const convertToBase64 = (fileToLoad): Promise<string> => {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    let base64;
    fileReader.addEventListener("load", (e: any) => {
      base64 = e.target.result;
      resolve(base64.split(",")[1]);
    });
    fileReader.readAsDataURL(fileToLoad);
  });
};

export const randomChars = (length) => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charsLength = chars.length;
  let output = "a";
  for (let i = 0; i < length; i++) {
    output += chars[Math.floor(Math.random() * charsLength)];
  }
  return output;
};

export const truncateChars = (value: string, limit: number): string => {
  // let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
  // let trail = args.length > 1 ? args[1] : '...';

  return value.length > limit ? value.substring(0, limit) + "..." : value;
};

export const FIND_KEY_IN_OBJECT_BY_VALUE = (obj, value) => {
  for (const key in obj) {
    if (obj[key] === value) {
      return key;
    }
  }
};

// ARTICLES

// Event

// Contribution_sfse
// Formation,
// Infos_divers
// Job_announce
// Project_appeal
// Public_politic
// Publication_sfse
// Ri
// Useful_doc
// Web_tool
