import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-autocomplete-field",
  templateUrl: "./autocomplete-field.component.html",
  styleUrls: ["./autocomplete-field.component.css"],
})
export class AutocompleteFieldComponent implements OnInit {
  @Output() output = new EventEmitter<{}>();
  @Input() attribute: string;
  @Input() suggestionId: string;
  @Input() currentValue: string;
  @Input() labelText: string;
  @Input() subLabelText: string;
  @Input() method: string;
  @Input() postData: any;
  @Input() reqType: string;
  @Input() labelIsBoldAndItalic: boolean;
  @Input() parentView: string;
  @Input() ctnr: any;
  @Input() inputClassName: string;

  suggestion_name: string;
  suggestions: any[];
  collection: any[];

  constructor() {}

  selectSuggestion(suggestion) {
    this.suggestions = [];
    this.suggestionId = suggestion.id;
    this.suggestion_name = suggestion.name;
    this.output.emit(suggestion);
  }

  emitSuggestion_name() {
    this.output.emit({ name: this.suggestion_name, id: null });
  }

  suggest($event) {
    this.emitSuggestion_name();
    this.suggestions = this.autocomplete();
    if (this.suggestion_name.length < 2) {
      if (this.ctnr) {
        const offsetBottom =
          this.ctnr.clientHeight -
          this.ctnr.getBoundingClientRect().y -
          $event.target.getBoundingClientRect().y;
        if (offsetBottom < 300) {
          document.getElementById(this.attribute).scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      } else {
        const offsetBottom =
          window.innerHeight - $event.target.getBoundingClientRect().y;
        if (offsetBottom < 300) {
          window.scrollBy({
            top: 300 - offsetBottom,
            behavior: "smooth",
          });
        }
      }
    }
  }

  autocomplete() {
    if (this.suggestion_name === "") {
      return [];
    }
    const txt = this.suggestion_name.toLowerCase();

    const filtered = this.collection.filter((suggestion) => {
      if (suggestion.name.toLowerCase().match(txt)) {
        return suggestion;
      }
    });
    return filtered;
  }

  ngOnInit() {
    const method = this.method ? this.method : "GET";
    const reqType = this.reqType ? this.reqType : "lire";
    const postData = this.postData ? this.postData : null;
    const url = apiURL(this.attribute, reqType, "2");
    ajax(
      url,
      method,
      postData,
      (res: any) => {
        this.collection = JSON.parse(res).records || [];
        // console.log(JSON.parse(res).records);
        this.collection = this.collection.map((item) => {
          if (item.accronym) {
            if (item.val) {
              item.val = item.accronym + " - " + item.val;
            } else if (item.name) {
              item.name = item.accronym + " - " + item.name;
            }
          }
          return item;
        });
        // console.log(this.collection);
        const index = this.collection.findIndex(
          (suggestion) => suggestion.id == this.suggestionId
        );
        if (index > -1) {
          this.suggestion_name =
            this.collection[index].name || this.collection[index].val;
        }
      },
      false
    );
  }
}
