import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { apiURL, ajax } from '../../../providers/helpers';


@Component({
  selector: 'app-work-section-details',
  templateUrl: './work-section-details.component.html',
  styleUrls: ['./work-section-details.component.css']
})
export class WorkSectionDetailsComponent implements OnInit {

  section;
  sectionDocs;

  constructor(private router: ActivatedRoute) { }

  ngOnInit() {
    window.scroll(0,0); 
    this.router.params.subscribe(data => {
      (data.sectionId);
      const url = apiURL('think_section', 'read_one', data.sectionId);
      ajax(url, 'GET', null, res => {
        const response = JSON.parse(res);
        if(!response.records) {return;} 
        this.section = response.records;
        if (this.section.img_data) {
          this.section.img_data = "url('data:image/jpg;base64," + this.section.img_data + "')";
        }
        const url2 = apiURL('think_section_doc', 'readBySection', data.sectionId);
        ajax(url2, 'GET', null, res => {
          const response = JSON.parse(res);
          if(!response.records) {return;} 
          this.sectionDocs = response.records;
          
        }, false);    
      }, false);  
    });
  }

}
