import { Component, OnDestroy, OnInit } from "@angular/core";

import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { TABLES_DISPLAYED_NAMES } from "src/app/providers/global";
import {
  ajax,
  apiURL,
  FIND_KEY_IN_OBJECT_BY_VALUE,
  truncateChars,
} from "../../../../providers/helpers";

@Component({
  selector: "app-posts-actions",
  templateUrl: "./posts-actions.component.html",
  styleUrls: ["./posts-actions.component.css"],
})
export class PostsActionsComponent implements OnInit, OnDestroy {
  loading = false;
  table: any;
  faSearch = faSearch;
  thematics = [];
  searchText = "";
  items: any[] = [];

  navDataChanged;

  selectedItemIndex;
  selectedIndex;
  displayAside;

  thematicsFilterShouldOpen = false;

  eventsSubject: Subject<void> = new Subject<void>();

  urlType = "";
  urlName = "";

  loadItems(evt) {
    if (evt.records.length === 0) {
      this.loading = false;
    }
    this.items = [];
    this.setImageAndThematics(evt.records);
  }

  loadThematics() {
    const url = apiURL("thematic", "lire", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        const thematics = JSON.parse(res).records;
        this.thematics = thematics.map((thematic) => {
          thematic.isChecked = false;
          return thematic;
        });
      },
      false
    );
  }

  setThematics(id, index) {
    const url = apiURL(this.table.name + "_thematic", "lire", id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        const thematics = JSON.parse(res).records;
        this.items[index].thematics = thematics.map((item) => {
          return item;
        });
        this.items[index].thematicsParam =
          JSON.parse(res)
            .records.map((thematic) => {
              thematic.name = thematic.name.replace(/\//gi, "{%}");
              return thematic.name;
            })
            .join(",") || "0";
        this.items[index];
      },
      false
    );
  }

  setImageAndThematics(items) {
    this.items = items.map((item, i) => {
      if (item.img_data) {
        item.img_data = "data:image/jpg;base64," + item.img_data;
      }
      item.description_short = truncateChars(item.description_short, 220);
      this.setThematics(item.id, i);
      item.titleForURL = item.title.split(" ").join("-");

      return item;
    });

    this.loading = false;
  }

  openThematicsFilterOrSearch() {
    this.thematicsFilterShouldOpen = !this.thematicsFilterShouldOpen;
    // const selectedThematics = this.thematics.filter(thematic => thematic.isChecked);
  }

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.navDataChanged = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.table) return;

        this.loading = true;

        const paginationMethod = this.table.method + "";
        this.table.method = "false";
        setTimeout(() => {
          this.table.method = paginationMethod;
        }, 400);
      }
    });
  }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.params.subscribe((data) => {
      this.table = { name: "", method: "false", type: "" };
      this.table.name = FIND_KEY_IN_OBJECT_BY_VALUE(
        TABLES_DISPLAYED_NAMES,
        data.tableName
      );

      this.table.type = FIND_KEY_IN_OBJECT_BY_VALUE(
        TABLES_DISPLAYED_NAMES,
        data.type
      );

      this.urlType = data.type;
      this.urlName = data.tableName;

      this.table.method = data.method;

      this.loadThematics();

      this.selectedIndex = data.selectedIndex;
      this.selectedItemIndex = data.selectedItemIndex;
      this.displayAside = false;
      setTimeout(() => {
        this.displayAside = true;
      }, 10);
    });
  }

  ngOnDestroy() {
    if (this.navDataChanged) {
      this.navDataChanged.unsubscribe();
    }
  }
}
