import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';


@Component({
  selector: 'app-adherents',
  templateUrl: './adherents.component.html',
  styleUrls: ['./adherents.component.css']
})
export class AdherentsComponent implements OnInit {

  noImgSrc = '../../../../assets/sfse-logo.png';
  firstAdherents = [];
  allAdherents  = [];
  adherent: any = null; 
  pdf_id = null;
  loading = false;

  constructor() { }

  navItems: [];

  displayAdherentDetails(id, img_data) {
    const url = apiURL('moral_person', 'read_one_adherent', id);
    ajax(url, 'GET', null, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      this.adherent = response.records;
      this.adherent.img_data = img_data;
      this.adherent.id = id;      
    }, false);
  }

  readPartners(to, propName, orderBy) {
    this.loading = true;
    const partnerUrl = apiURL('moral_person', 'lire-details', '');
    ajax(partnerUrl, 'POST', {to, orderBy}, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      const adherents = response.records;
      adherents.forEach((adherent, index) => {
        if(adherent.img_data) {
          adherent.img_data = 'data:image/jpg;base64,' + adherent.img_data;
        }
        this[propName].push(adherent);
      });
      this.loading = false;
    }, false);
  }

  readAllPartners() {
    this.readPartners(100, "allAdherents", "name");
  }

  ngOnInit() {
    window.scroll(0,0);
    this.readPartners(4, "firstAdherents", null);
  }

}
