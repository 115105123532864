import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PdfGeneratorService } from '../../../providers/pdf-generator.service';

import { faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-congress-devis-form-popup',
  templateUrl: './congress-devis-form-popup.component.html',
  styleUrls: ['./congress-devis-form-popup.component.css']
})
export class CongressDevisFormPopupComponent implements OnInit {

  @Input() congress_debut: any;
  @Input() congress_end: any;
  @Input() congress_pricing_adherent_before: string;
  @Input() congress_pricing_adherent_after: string;
  @Input() congress_pricing_user_before: string;
  @Input() congress_pricing_user_after: string;
  @Input() congress_pricing_student: string;
  @Input() congress_pricing_limit_date: string;


  @Output() close = new EventEmitter();

  faTimes = faTimes;

  devis: any = {
    billingAddress: '',
    resaAdherentNbr : 0,
    resaNonAdherentNbr: 0,
    resaDiscountNbr: 0
  }

  numbers = [1,2,3,4,5,6,7,8,9,10];

  constructor(private pdfGeneratorService: PdfGeneratorService) { }

  _close() {
    this.close.emit();
  }

  generatePdfDevisCongres() {
    const infos: any = {
      address: [
        this.devis.billingAddress
      ],
      devisNumber: new Date().getFullYear() + '-' + new Date().getTime(),
      congressDebut: this.congress_debut,
      congressEnd: this.congress_end,
      pricings: {
        discount: this.congress_pricing_student
      },
      resaAdherentNbr: this.devis.resaAdherentNbr,
      resaNonAdherentNbr: this.devis.resaNonAdherentNbr,
      resaDiscountNbr: this.devis.resaDiscountNbr
    };

    if(new Date(this.congress_pricing_limit_date) < new Date()) {
      infos.pricings.nonAdherent = this.congress_pricing_user_after;
      infos.pricings.adherent = this.congress_pricing_adherent_after;
    } else {
      infos.pricings.nonAdherent = this.congress_pricing_user_before;
      infos.pricings.adherent = this.congress_pricing_adherent_before;
    }
    this.pdfGeneratorService.generatePdf('devis-congres', infos);
  }

  ngOnInit() {
    window.scroll(0,0); 
  }

}
