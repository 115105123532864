export default class User {
  id: string;
  img_id: string;
  role_id: string;
  role_name: string;
  civility_id: string;
  civility: string;
  status_id: string;
  status_name: string;
  organization_id: string;
  country_id: string;
  country_name: string;
  region_id: string;
  region_name: string;
  firstname: string;
  name: string;
  mail: string;
  mailConfirm: string;
  pwd: string;
  pwdConfirm: string;
  phone: string;
  cellphone: string;
  mail_pro: string;
  activity_domain_id: string;
  organization_2: string;
  full_address: string;
  city: string;
  postal_code: string;
  competence_id: string;
  competence2_id: string;
  competence3_id: string;
  occupied_function: string;
  linkedin: string;
  ers_paper: string;
  is_visible: string;
  adhesionJustExpired: string;
  register_end_date: string;
  organization_name: string;
  organization_subtitle: string;
  mail_is_public: string;

  constructor(props) {
    this.implement(props);
  }

  implement(props) {
    for (const key in props) {
      if (props[key] && this.getProps().indexOf(key) > -1) {
        if (key !== "pw") {
          this[key] = props[key];
        }
      }
    }
    if (!this.role_id) {
      this.role_id = "4";
    }
  }

  getProps() {
    return [
      "id",
      "role_id",
      "civility",
      "civility_id",
      "status_id",
      "organization_id",
      "country_id",
      "country_name",
      "region_id",
      "region_name",
      "firstname",
      "name",
      "mail",
      // 'pwd',
      "phone",
      "cellphone",
      "mail_pro",
      "activity_domain_id",
      "organization_2",
      "full_address",
      "city",
      "postal_code",
      "competence_id",
      "competence2_id",
      "competence3_id",
      "occupied_function",
      "linkedin",
      "ers_paper",
      "is_visible",
      "adhesionJustExpired: string",
      "register_end_date: string",
      "organization_name: string",
      "role_name",
      "status_name",
      "organization_subtitle",
      "mail_is_public",
    ];
  }
}
