import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donnation',
  templateUrl: './donnation.component.html',
  styleUrls: ['./donnation.component.css']
})
export class DonnationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0, 0);
    setTimeout(() => {
      window.scroll(0, 0);
    }, 2000)
  }

}
