import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ERS_URL } from "../../../providers/global";
import { getStoredUser } from "../../../providers/user-service";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MembershipMainComponent implements OnInit {
  user: any = {};

  navItems = [
    {
      title: "Adhérer : Personnes physiques",
      img: "../../../../assets/adhesion-nav-imgs/nav-adhesion-physique.jpg",
      url: "espace_perso/adhesion_personne_physique",
      subtitle: "Adhérez à titre individuel",
    },
    {
      title: "Adhérer : Personnes morales",
      img: "../../../../assets/adhesion-nav-imgs/nav-adherer-personne-morale.jpg",
      url: "espace_perso/adhesion_personne_morale",
      subtitle: "Faites adhérer votre organisme",
    },
    {
      title: "S'inscrire au congrès SFSE",
      img: "../../../../assets/adhesion-nav-imgs/nav-congres-inscription.jpg",
      url: "espace_perso/inscription_congres",
      subtitle:
        "Faites le plein de connaissances grâce à l’événement majeur de la Santé-Environnement francophone",
      visible_by: "user",
    },
    {
      title: "Soumettre un résumé pour le congrès à venir",
      img: "../../../../assets/adhesion-nav-imgs/nav-resume-congres.jpg",
      url: "espace_perso/resume_congres",
      visible_by: "user",
    },
    {
      title: "Lire la revue Environnement, Risques et Santé (ERS)",
      img: "../../../../assets/adhesion-nav-imgs/nav-ers.jpg",
      url: ERS_URL,
      visible_by: "adherent",
      externalLink: true,
    },
    {
      title:
        "Annuaire en ligne des personnes impliquées en santé environnement",
      img: "../../../../assets/adhesion-nav-imgs/nav-annuaire.jpg",
      url: "espace_perso/annuaire",
      visible_by: "adherent",
    },
    {
      title: "Mes sections de reflexion",
      img: "../../../../assets/adhesion-nav-imgs/nav-mes-sections-de-reflexion.jpeg",
      url: "espace_perso/mes_sections_de_reflexion",
      visible_by: "adherent",
      subtitle:
        "Consultez ici la documentation mise à disposition dans le cadre des sections de réflexion que vous suivez",
    },
    {
      title: "Recevoir la lettre d’information SFSE",
      img: "../../../../assets/adhesion-nav-imgs/nav-newsletter.jpg",
      url: "espace_perso/profil",
      subtitle:
        "Restez connecté.e aux actualités de l’association et des mises à jour du site",
      visible_by: "user",
    },
    {
      title: "Faire un don pour soutenir les activités de la SFSE",
      img: "../../../../assets/adhesion-nav-imgs/nav-faire-un-don.jpg",
      url: "espace_perso/nous_aider",
    },
    {
      title: "Mon Profil",
      img: "../../../../assets/adhesion-nav-imgs/nav-profil.jpg",
      url: "espace_perso/profil",
      visible_by: "user",
      subtitle:
        "Gérez ici les informations vous concernant après la création de votre compte ou votre adhésion « Personne physique »",
    },
  ];

  constructor(private router: Router) {}

  goToNavItem(url) {
    if (url === ERS_URL) {
      window.open(ERS_URL, "_blank");
      return;
    }

    this.router.navigate([url]);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.user = getStoredUser();
    // console.log(this.user);
  }
}
