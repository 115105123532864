import User from '../models/User';
import StorageService from '../models/Storage';

export const getStoredUser = () => {
    const storage = new StorageService();
    return storage.getItem('user') || {};
}

export const updateStoredUser = user => {
    const storage = new StorageService();
    storage.setItem('user', user);
}
