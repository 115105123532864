import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-template',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainTemplateComponent implements OnInit {

  @Input() navItems : [];
  @Input() title : string;


  constructor(private router: Router) {}

  goToNavItem(navItem) {
    this.router.navigate([navItem.url]);
  }

  ngOnInit() {}

}
