import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';


@Component({
  selector: 'app-select-multi',
  templateUrl: './select-multi.component.html',
  styleUrls: ['./select-multi.component.css']
})
export class SelectMultiComponent implements OnInit {
  @Input() table1: string;
  @Input() table2: string;
  @Input() itemId: string;
  @Input() itemName: string;
  @Input() method1: string;
  @Input() method2: string;
  @Input() newItem: string;
  @Output() output = new EventEmitter<{ options: string; table_name: string; }>(); 
  @Output() optionsWereAdded = new EventEmitter<Boolean>(); 

  currentProps    = []; // propriétés actuellement rattachées à l'élément cible
  options         = []; // otpions du select
  selectedProps   = []; // options sélectionées à rattacher à l'élément cible
  selectedPropId;       // id de l'option sélectionnée en dernier
  
  join_table_name: string;

  constructor() { }

  validate() {
    const selections = this.selectedProps.map(item => item.id).join(',');

    const data            = {};
    const itemId          = this.table1 + '_id';
    const propIds         = this.table2 + '_ids';
    
    data[propIds]         = selections;
    data[itemId]          = this.itemId;

    const url = apiURL(this.join_table_name, 'update', '');
    ajax(url, 'POST', data, (res2: any) => {
      // this.output.emit(`Vos ${this.itemName} ont bien été mis à jour !`);
      this.currentProps = [... this.selectedProps];
      if(this.currentProps.length > 0) {
        this.optionsWereAdded.emit(true);
      }
      this.selectedProps = [];
    }, false);
  }

  onChange() {
    const index = this.options.findIndex(option => option.id == this.selectedPropId);
    if(index > -1 && this.selectedProps.findIndex(prop => prop.id == this.selectedPropId) < 0) {
      this.selectedProps.push({name: this.options[index].name || this.options[index].val, id: this.selectedPropId});
    }
    
    this.output.emit({options: this.selectedProps.map(item => item.id).join(','), table_name: this.join_table_name});
    
    
  }

  cancelChoices() {
    this.selectedProps = [];
    this.output.emit({options: '', table_name: this.join_table_name});
  }

  ngOnInit() {
    // chargement de la liste de toutes les propriétés possibles à rattacher à l'item via le select multiple
   this.join_table_name = this.table1 + '_' + this.table2;
   
   (this.join_table_name);
    const method1 = this.method1 || 'lire';
    const url = apiURL(this.table2, method1, '');
    ajax(url, 'GET', null, (res: any) => {
     
      this.options = JSON.parse(res).records;
    }, false);

    // chargement des propriétés jointes à l'item à créer / modifier
    // (exemple : toutes les thématiques rattachées à un événement => table "event_thematic")
    const method2 = this.method2 || 'lire';
    const url2 = apiURL(this.join_table_name, method2, this.itemId);
    ajax(url2, 'GET', null, (res: any) => {
     
      this.currentProps = JSON.parse(res).records;
      if(this.currentProps.length > 0) {
        this.optionsWereAdded.emit(true);
      }
      // [{id: id_de_la_propriete, name: son_nom}, { ... }, { ... }]
    }, false);
  }

}
