import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-infos',
  templateUrl: './infos.component.html',
  styleUrls: ['./infos.component.css']
})
export class InfosComponent implements OnInit {

  navItem = { icon: 'acts' };
  congress : any = {};
  infos: any[];
  sideNav2;
  user: any = {};

  constructor(
    private congressService: CongressService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute) {}

  getInfos() {
    const url = apiURL('congress_info', 'lire', this.congress.id);
    ajax(url, 'GET', null, (res: any) => {
     
      this.infos = JSON.parse(res).records;
    }, false);
  }
  
  ngOnInit() {
    window.scroll(0,0); 
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('infos', data.id).then((data: any) => {
        this.user     = data.user;
        this.congress = data.congress;
        this.sideNav2 = data.sideNav2;
        this.getInfos();
      }).catch(err => {
        // console.log(err);
      });
    });
  }
}
