import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavData } from '../../../providers/NavData';
import { getStoredUser } from '../../../providers/user-service';
import { ADHESION_PHYSIC_NAV_TEXT, ERS_URL, NAV_ITEM_TITLE_NOUS_REJOINDRE } from '../../../providers/global'
import { faBars } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside-nav.component.html',
  styleUrls: ['./aside-nav.component.css']
})
export class AsideNavComponent implements OnInit {

  @Input() extraTitle: any;
  @Input() asideNavIndex: any;
  @Input() selectedItemIndex: any;
  @Input() sideNav2: any;
  @Input() alternateTitle: any;


  faBars = faBars;
  showSideMenu = false;

  user = {role_name: ''};
  asideNavItems;
  selectedNavItem;
  navItemColor;
  isMembership;
  loggedInTitle;
  topAsideTitle;

  congressStyle:any;
  // congress;

  constructor(private router: Router){}

  goTo(url) {
    
    if(url === ERS_URL) {
      window.open(ERS_URL, '_blank');
      return;
    }
    this.router.navigate([url]);
    window.scroll(0,0);
  }

  switchShowSideMenuValue() {
    this.showSideMenu = !this.showSideMenu;
  }

  ngOnInit() {
    this.user = getStoredUser();
    const asideNavItem = NavData.getAsideNavItems(this.asideNavIndex);
    if(asideNavItem.title == NAV_ITEM_TITLE_NOUS_REJOINDRE) {
      if(this.user.role_name == "Adhérent") {
        asideNavItem.sideNav[0].title = "Renouveler mon adhésion";
      } else {
        asideNavItem.sideNav[0].title = ADHESION_PHYSIC_NAV_TEXT;
      }
     
    }
    this.asideNavItems = this.sideNav2 ? asideNavItem.sideNav2 : asideNavItem.sideNav;

    this.selectedNavItem = this.asideNavItems[this.selectedItemIndex];
    this.navItemColor = asideNavItem.color;
    this.isMembership = asideNavItem.isMembership;
    this.loggedInTitle = asideNavItem.loggedInTitle;
    this.topAsideTitle = asideNavItem.title;
    // if(this.selectedNavItem.title === 'Congrès') {
    //   this.congressStyle = {'color': 'var(--sfse-dark-green)', 'background-color': 'transparent'};
    //   // this.selectedNavItem.title = this.extraTitle;
    // } 

    // console.log(this.user);
  }

}
