import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { apiURL, ajax } from '../../../../providers/helpers';



@Component({
  selector: 'app-popup-adherent-moral-details',
  templateUrl: './popup-adherent-moral-details.component.html',
  styleUrls: ['./popup-adherent-moral-details.component.css']
})
export class PopupAdherentMoralDetailsComponent implements OnInit {


  // faEnvelope  = faEnvelope;
  // faPhone     = faPhone;
  faTimes     = faTimes;

  // activityDomains = [];
  thematics = [];


  @Input() adherent : any;
  @Output() close = new EventEmitter();

  constructor() { }

  closePopup() {
    this.close.emit();
  }

  loadItems(table_name, method, propName) {
    const url = apiURL(table_name, method, this.adherent.id);
    ajax(url, 'GET', null, (res: any) => {
      this[propName] = JSON.parse(res).records;
    }, false);
  }

  loadThematics() {
    this.loadItems('moral_person_thematic', 'lire', 'thematics');
  }
  
  ngOnInit() {
    this.loadThematics();
    // console.log(this.adherent);
  }

}
