import { Component, OnInit } from '@angular/core';
import { NavItem } from '../../../models/NavItem';
import { NavData } from '../../../providers/NavData';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class RdMainComponent implements OnInit {

  constructor() { }

  navItems : NavItem[];
  
  ngOnInit() {  
    window.scroll(0,0);
    this.navItems = NavData.getAsideNavItems(4).sideNav;
  }

}
