import { Component, OnInit } from '@angular/core';
import { getStoredUser } from '../../../providers/user-service';
import { AVANTAGES_ADHESION_PHYSIC_PDF_NAME } from '../../../providers/global';
import { apiURL, ajax } from '../../../providers/helpers';


@Component({
  selector: 'app-documentary-center',
  templateUrl: './documentary-center.component.html',
  styleUrls: ['./documentary-center.component.css']
})
export class DocumentaryCenterComponent implements OnInit {

  avantages_adhesion_physic_pdf_name = AVANTAGES_ADHESION_PHYSIC_PDF_NAME;


  user;
  pdf_id;
  constructor() { }

  showPdf(pdfName) {
    const url = apiURL('pdf', 'read_one_id', '');

    ajax(url, 'POST', {name: pdfName}, res => {
      let response: any = res;
      (response);
      response = JSON.parse(response);
      this.pdf_id = response.records.id;
    }, false);
  }

  ngOnInit() {
    window.scroll(0,0);
    this.user = getStoredUser();
  }

}
