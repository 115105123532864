import { Component, OnInit } from "@angular/core";
import {
  AVANTAGES_ADHESION_PHYSIC_PDF_NAME,
  IMG_FOLDER_USER_ID,
} from "../../../providers/global";
import {
  getStoredUser,
  updateStoredUser,
} from "../../../providers/user-service";

import { ajax, apiURL, resizeImage } from "../../../providers/helpers";

@Component({
  selector: "app-profil",
  templateUrl: "./profil.component.html",
  styleUrls: ["./profil.component.css"],
})
export class ProfilComponent implements OnInit {
  avantages_adhesion_moral_pdf_name = AVANTAGES_ADHESION_PHYSIC_PDF_NAME;

  user: any;
  originalUser: any;
  statuses;
  countries;
  regions;
  userOrganizationCategoryName = "";
  userOrganizationName = "";
  competences;
  thematics;
  thematicsSub;
  selectedThematicId;
  selectedThematicsSub = [];
  userThematicsSub;
  lastAdhesion;

  newPass = "";
  oldPass = "";
  updatePwd = false;
  pdf_id = "";

  popupText = "";
  showPopup = false;

  constructor() {}

  emitPhone($event, propName) {
    this.user[propName] = $event;
  }

  closePopupAndWindow() {
    this.showPopup = null;
  }

  showPdf() {
    const url = apiURL("pdf", "read_one_id", "");

    ajax(
      url,
      "POST",
      { name: AVANTAGES_ADHESION_PHYSIC_PDF_NAME },
      (res) => {
        let response: any = res;
        response = JSON.parse(response);
        this.pdf_id = response.records.id;
      },
      false
    );
  }
  passwordIsValid(pwd, validPropName) {
    if (pwd.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      this[validPropName] = true;
    } else {
      this[validPropName] = false;
    }
  }

  updatePassword() {
    const url = apiURL("user", "update_pass", "");
    ajax(
      url,
      "POST",
      { id: this.user.id, newPass: this.newPass, oldPass: this.oldPass },
      (res: any) => {
        alert(JSON.parse(res).message);
        this.updatePwd = false;
      },
      false
    );
  }

  loadUser() {
    return new Promise((resolve, reject) => {
      let user = getStoredUser();
      const url = apiURL("user", "read_one", user.id);
      ajax(
        url,
        "GET",
        null,
        (res: any) => {
          // console.log(res);
          resolve(JSON.parse(res).records);
        },
        false
      );
    }).catch((err) => {
      throw err;
    });
  }

  loadItems(table_name, method, propName, id) {
    const url = apiURL(table_name, method, id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this[propName] = JSON.parse(res).records;
      },
      false
    );
  }

  loadUserStatus() {
    this.loadItems("user_status", "lire", "statuses", this.user.id);
  }

  loadCountries() {
    this.loadItems("country", "lire", "countries", this.user.id);
  }

  loadRegions(countryId) {
    this.loadItems("region", "lire", "regions", countryId);
  }

  loadCompetences() {
    this.loadItems("competence", "lire", "competences", this.user.id);
  }

  loadThematics() {
    this.loadItems("thematic", "lire", "thematics", null);
  }

  loadThematicsSub(thematicId) {
    this.selectedThematicId = thematicId;
    this.loadItems(
      "thematic_sub",
      "read_by_thematic",
      "thematicsSub",
      thematicId
    );
  }

  loadUserThematicsSub() {
    this.loadItems(
      "user_thematic_sub",
      "lire",
      "userThematicsSub",
      this.user.id
    );
  }

  loadUserAdhesions() {
    this.loadItems(
      "adhesion_physic",
      "read_by_user",
      "lastAdhesion",
      this.user.id
    );
  }

  updateInfos(propName, warningMsg = null, propName2?, propName3?) {
    if (warningMsg && !window.confirm(warningMsg)) {
      return;
    }

    const value = this.user[propName];
    const data = { id: this.user.id, propName, value };
    const url = apiURL("user", "update_one", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        this.originalUser[propName] = value;
        if (propName2) this.originalUser[propName2] = this.user[propName2];
        if (propName3) this.originalUser[propName3] = this.user[propName3];
        updateStoredUser(this.originalUser);
      },
      false,
      (error) => {
        this.popupText = JSON.parse(error).message;
        this.showPopup = true;
      }
    );
  }

  setOrganizationSuggestion(evt) {
    this.userOrganizationCategoryName = evt.cat_name;
    this.userOrganizationName = evt.name;
    this.user.organization_id = evt.id;
    this.user.organization_name = evt.name;
    this.user.organization_cat_name = evt.cat_name;
  }

  createOrganization() {
    const url = apiURL("organization", "create", "");
    ajax(
      url,
      "POST",
      { name: this.userOrganizationName },
      (res: any) => {
        this.user.organization_id = JSON.parse(res).records.id;
        this.user.organization_name = this.userOrganizationName;
        this.updateInfos("organization_id", null, "organization_name");
      },
      false,
      (err) => {
        alert("une erreur est survenue");
        err;
      }
    );
  }

  regionWasSelected(event) {
    const index = this.regions.findIndex(
      (region) => region.id == event.target.value
    );
    this.user.region_name = this.regions[index].name;
    updateStoredUser(this.user);
  }

  selectSubThematic(thematic) {
    if (
      this.selectedThematicsSub.findIndex((item) => item.id == thematic.id) < 0
    ) {
      this.selectedThematicsSub.push(thematic);
    }
  }

  clearThematicsSub() {
    this.selectedThematicsSub = [];
  }

  postThematicsSub() {
    const userThematicsSubData = {
      thematic_sub_ids: this.selectedThematicsSub
        .map((item) => item.id)
        .join(","),
      user_id: this.user.id,
    };
    const userThematicsSubUrl = apiURL("user_thematic_sub", "update", "");
    ajax(
      userThematicsSubUrl,
      "POST",
      userThematicsSubData,
      (res: any) => {
        this.userThematicsSub = [...this.selectedThematicsSub];
        this.selectedThematicsSub = [];
        this.popupText = "Vos thématiques d'intérêt ont été mises à jour";
        this.showPopup = true;
      },
      false
    );
  }

  async init() {
    window.scroll(0, 0);
    this.user = await this.loadUser();
    if (this.user.img_data) {
      this.user.img_data = "data:image/jpg;base64," + this.user.img_data;
    }

    this.loadUserStatus();
    this.loadCountries();
    this.loadRegions(this.user.country_id);

    if (this.user.role_id == 3) {
      this.loadCompetences();
      this.loadThematics();
      this.loadUserThematicsSub();
    }

    this.loadUserAdhesions();
    return this.user;
  }

  chosenImg;
  chosenImgSrc;
  resizedImg;
  resizedImgSrc;

  setImgToUpload(e) {
    this.chosenImg = e.target.files[0];
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(this.chosenImg);

    reader.onloadend = (e: any) => {
      this.chosenImgSrc = reader.result;

      resizeImage(e, this.chosenImg.name, this.chosenImg.type, 0.2, 50).then(
        (resizedImg: File) => {
          this.resizedImg = resizedImg;

          reader.readAsDataURL(this.resizedImg);
          reader.onloadend = () => {
            this.resizedImgSrc = reader.result;
          };
        }
      );
    };
  }

  updateOne(propName, value) {
    return new Promise((resolve, reject) => {
      const data = { id: this.user.id, propName, value };
      const url = apiURL("user", "update_one", "");
      ajax(
        url,
        "POST",
        data,
        (res: any) => {
          resolve("success");
        },
        false,
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateImg(old_img_id, new_img_id, user_id) {
    return new Promise((resolve, reject) => {
      const data = { id: user_id, old_img_id, new_img_id };
      const url = apiURL("user", "update_img", "");
      ajax(
        url,
        "POST",
        data,
        (res: any) => {
          resolve(JSON.parse(res));
        },
        false,
        (err) => {
          reject(err);
        }
      );
    });
  }

  updateUserImage(e) {
    const formData = new FormData(e.target);
    formData.append("folderId", String(IMG_FOLDER_USER_ID));
    formData.append("type", this.chosenImg.type);
    formData.append("img", this.resizedImg);

    const imgURL = apiURL("img", "create", "");
    ajax(
      imgURL,
      "POST",
      formData,
      async (res) => {
        const dataRes = JSON.parse(res);

        await this.updateImg(this.user.img_id, dataRes.imgId, this.user.id);
        this.user.img_id = dataRes.imgId;

        this.originalUser.img_id = this.user.img_id;
        updateStoredUser(this.originalUser);
        this.chosenImg = null;
        // this.resizedImg = null;
        // this.chosenImgSrc = null;
      },
      true
    );
  }

  getUserImgData(img_id) {
    return new Promise((resolve, reject) => {
      const url = apiURL("img", "read_one", img_id);
      ajax(
        url,
        "GET",
        null,
        (res: any) => {
          // console.log(res);
          resolve(res);
        },
        false,
        (err) => {
          reject(err);
        }
      );
    });
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.init().then((user) => {
      if (!user.newsletter) user.newsletter = "0";
      if (!user.is_visible) user.is_visible = "0";
      this.originalUser = { ...user };
    });
  }
}
