import { Component, OnInit } from '@angular/core';
import { faMapMarkerAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import Storage from '../../../models/Storage';
import { ajax, apiURL } from '../../../providers/helpers';
import { Router } from '@angular/router';
import { getStoredUser } from 'src/app/providers/user-service';

@Component({
  selector: 'app-congreses-list',
  templateUrl: './congreses-list.component.html',
  styleUrls: ['./congreses-list.component.css']
})
export class CongresesListComponent implements OnInit {

  faMapMarkerAlt = faMapMarkerAlt;
  faCalendarAlt = faCalendarAlt;

  congresses: any[];
  storage = new Storage();
  loading     = false;


  congress;

  constructor(private router: Router) { }

  loadCongress(id) {
    const url = apiURL('congress', 'read_one', id);
    ajax(url, 'GET', null, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;}
      this.congress = response.records; 
      this.congress.img_data  =  this.congress.img_data ? `url("data:image/jpg;base64,${this.congress.img_data}")` : 'url("../../../../assets/test/event-2.jpg")';
      this.congress.img_data2  =  this.congress.img_data2 ? 'data:image/jpg;base64,' + this.congress.img_data2 : '../../../../assets/test/event-1.jpg';
      this.storage.setItem('congress', this.congress);
      // NavData.setCurrentNavSectionIndex(0);
      this.router.navigate(['/congres/presentation/null']);
    }, false)
  }

  backToActiveCongress() {
    this.storage.setItem('congress', null);
    this.router.navigate(['/congres/presentation/null']);
  }

  ngOnInit() { 
    window.scroll(0,0); 
    this.loading = true;
    this.storage = new Storage();
    this.congress = this.storage.getItem('congress');
  
    const url = apiURL('congress', 'lire-details', '');
    ajax(url, 'GET', null, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      this.congresses = response.records.map(congress => {
        if (congress.img) {
          congress.img = 'data:image/jpg;base64,' + congress.img;
        } else {
          congress.img = 'url("../../../../assets/test/event-2.jpg")';
        }
        return congress;
      });
      this.loading = false;
    }, false, err => {
      // console.log(err);
      this.loading = false;
    });   
  }

}


