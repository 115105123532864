import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';

@Component({
  selector: 'app-bilans',
  templateUrl: './bilans.component.html',
  styleUrls: ['./bilans.component.css']
})
export class BilansComponent implements OnInit {

  constructor() { }

  navItems: [];
  noImgSrc = '../../../../assets/sfse-logo.png';
  balanceSheets = [];
  pdf_id = null;

  ngOnInit() {
    window.scroll(0,0);
    const balanceSheetUrl = apiURL('annual_balance_sheet', 'lire-details', '');
    ajax(balanceSheetUrl, 'GET', null, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      const balanceSheets = response.records;
      this.balanceSheets = balanceSheets.map((balanceSheet, index) => {
        balanceSheet.index = index;
        balanceSheet.img = balanceSheet.img && 'data:image/jpg;base64,' + balanceSheet.img || '../../../../assets/sfse-logo.png';
        return balanceSheet;
      });

    }, false);
  }

}
