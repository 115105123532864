import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NAV_URLS } from "../../../../providers/NavData";

import {
  faArrowLeft,
  faCalendar,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";

@Component({
  selector: "app-tehmatic-search-details",
  templateUrl: "./tehmatic-search-details.component.html",
  styleUrls: ["./tehmatic-search-details.component.css"],
})
export class TehmaticSearchDetailsComponent implements OnInit {
  constructor(private activateRoute: ActivatedRoute, private router: Router) {
    // this.navDataChanged = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     if(!this.table) return;
    //     const paginationMethod = this.table.method + '';
    //     this.table.method = 'false';
    //     setTimeout(() => {
    //       this.table.method = paginationMethod;
    //     }, 400)
    //   }
    // });
  }

  faArrowLeft = faArrowLeft;
  faCalendar = faCalendar;
  faMapMarker = faMapMarker;

  thematicName;
  thematicId;
  table: any;
  tables = <any>[];

  items = <any>[];
  eventsSubject: Subject<void> = new Subject<void>();
  navDataChanged;

  loadPagination: boolean;

  loadItems(evt, index) {
    this.items[index] = [];
    this.sortItemsWithImage(evt.records, index);
  }

  sortItemsWithImage(items, index) {
    items;
    this.items[index] = items.map((item, i) => {
      if (item.img_data) {
        item.img_data = "data:image/jpg;base64," + item.img_data;
      }
      return item;
    });
  }

  goToPostDetails(element) {
    const tableName = element.table_name;
    const postId = element.id;

    let navUrl;
    if (tableName === "event") {
      if (element.val_act_id) {
        navUrl = NAV_URLS.event.val_act;
      } else {
        navUrl = NAV_URLS.event.event;
      }
    } else {
      navUrl = NAV_URLS[tableName];
    }

    let splitedNavUrl = navUrl.split("/");
    splitedNavUrl.splice(2, 0, element.title);
    const navUrlWithTitle = splitedNavUrl.join("/");
    this.router.navigate([`${navUrlWithTitle}/${postId}`]);
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.activateRoute.params.subscribe((data) => {
      this.thematicName = data.thematic_name;
      this.thematicId = data.thematicId;

      this.tables = [
        {
          title: "Manifestations francophones futures et passées",
          name: "event",
          icon: "agenda",
          type: "event",
        },
        {
          title: "Actes de valorisation des événements",
          name: "event",
          icon: "acts",
          type: "val_act",
        },
        {
          title: "Appels à projets et contributions",
          name: "project_appeal",
          icon: "project_appeal",
        },
        { title: "Formations", name: "formation", icon: "formation" },
        { title: "Documentation", name: "useful_doc", icon: "useful_doc" },
        { title: "Annonces emplois", name: "job_announce", icon: "agenda" },
        { title: "Outils web", name: "web_tool", icon: "job_announce" },
        {
          title: "Politiques publiques",
          name: "public_politic",
          icon: "public_politic",
        },
        {
          title: "Informations diverses",
          name: "infos_divers",
          icon: "infos_divers",
        },
        {
          title: "Publications SFSE",
          name: "publication_sfse",
          icon: "publication_sfse",
        },
        {
          title: "Contributions SFSE",
          name: "contribution_sfse",
          icon: "contribution_sfse",
        },
        { title: "Relations internationales", name: "ri", icon: "ri" },
      ].map((table: any) => {
        const table_ = {
          table: table.name,
          title: table.title,
          icon: table.icon,
          name: "filter_by_thematic",
          method: "lire",
          thematic_id: this.thematicId,
          type: table.type,
          is_past: table.is_past,
        };
        return table_;
      });
    });
  }
}
