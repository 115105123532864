import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-acts',
  templateUrl: './acts.component.html',
  styleUrls: ['./acts.component.css']
})
export class ActsComponent implements OnInit {

  congress_acts = [];
  congress : any = {};
  storage;
  pdf_id;

  constructor(
    private congressService: CongressService, 
    private activatedRoute: ActivatedRoute) {}

  displayPdf(id) {
    this.pdf_id = id;
  }

  getCongressActs(congressId) {
    const url = apiURL('congress_act', 'read_by_congress_id', congressId);
    ajax(url, 'GET', null, (res: any) => {
    
      this.congress_acts = JSON.parse(res).records;
      // console.log(res);
    }, false);
  }

  ngOnInit() {
    window.scroll(0,0); 
    this.activatedRoute.params.subscribe( async data => {
      this.congressService.init('actes', data.id).then((data: any) => {
        this.congress = data.congress;
        this.getCongressActs(this.congress.id);
      }).catch(err => {
        // console.log(err);
      });
    });
  }
}



