import { Injectable } from '@angular/core';
import { REGULAR_SIGNUP_PRICE, STUDENT_SIGNUP_PRICE, PROMO_CODE_SCIENTIFIC_SOCIETY_PRICE, ERS_SUBSCRIPTION_AMOUNT, SCIENTIFIC_SOCIETY_PRICE, ASSOCIATION_LOW_PRICE, ASSOCIATION_HIGH_PRICE, COLLECTIVITY_LOW_PRICE, COLLECTIVITY_HIGH_PRICE, INTERNATIONAL_PRICE, PRIVATE_ORGANIZATION_LOW_PRICE, PRIVATE_ORGANIZATION_HIGH_PRICE, CONGRESS_PROMO_CODE, FREE_PROMO_CODE, SCIENTIFIC_SOCIETY_PROMO_CODE } from './global'
import { apiURL, ajax, randomChars, timestampToDate } from './helpers';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  regular_price                         = REGULAR_SIGNUP_PRICE;
  student_price                         = STUDENT_SIGNUP_PRICE;
  promo_code_scientific_society_price   = PROMO_CODE_SCIENTIFIC_SOCIETY_PRICE;

  congress_promo_code                   = CONGRESS_PROMO_CODE;
  free_promo_code                       = FREE_PROMO_CODE;
  scientific_society_promo_code         = SCIENTIFIC_SOCIETY_PROMO_CODE;
  
  ers_subscription_amount               = ERS_SUBSCRIPTION_AMOUNT;
  
  scientific_society_price              = SCIENTIFIC_SOCIETY_PRICE;
  association_low_price                 = ASSOCIATION_LOW_PRICE;
  association_high_price                = ASSOCIATION_HIGH_PRICE;
  collectivity_low_price                = COLLECTIVITY_LOW_PRICE;
  collectivity_high_price               = COLLECTIVITY_HIGH_PRICE;
  private_organization_low_price        = PRIVATE_ORGANIZATION_LOW_PRICE;
  private_organization_high_price       = PRIVATE_ORGANIZATION_HIGH_PRICE;
  internationnal_price                  = INTERNATIONAL_PRICE;

  total;

  promoCodeValidated: string;

  constructor() { }

  reset(){
    this.total = 0;
    this.promoCodeValidated = "";
  }

  async calculatePhysicAdhesionAmount(is_student, ers_subscription, promoCode) {
    this.total = 0;
    const output = {adhesionAmount: 0, status: 'success', ersAmount: 0};

    this.total += is_student ? this.student_price : this.regular_price;

    if(promoCode) {
      const res:any = await this.checkPromoCode(promoCode, 'adhesion_physic');
      if(res.msg !== 'success') {
        output.status = 'error';

      } else {
        this.total = res.type_id == this.scientific_society_promo_code ? this.promo_code_scientific_society_price : 0;
      }
    }

    output.adhesionAmount = this.total + 0;

    if(ers_subscription) {
      this.total += this.ers_subscription_amount;
      output.ersAmount = this.ers_subscription_amount;
    }
    
    return output;
  }

  async calculateMoralAdhesionAmount(statusId, annualCaId, numberOfExtraAdhesion, promoCode, is_scientific_society) {
    this.total = 0;

    switch(parseInt(statusId)) {
      case 1 : 
        if(is_scientific_society == 'oui') {
          this.total += this.scientific_society_price;
        } else if(annualCaId == 1) {
          this.total += this.association_low_price;
        } else if(annualCaId == 2) {
          this.total += this.association_high_price;
        } else {
          return 'error';
        }
        break;
      case 2 : 
        if(annualCaId == 3) {
          this.total += this.collectivity_low_price;
        } else if (annualCaId == 4) {
          this.total += this.collectivity_high_price;
        } else {
          return 'error';
        }
        break;
      case 3 : 
        this.total += this.internationnal_price;
        break;
      case 4 : 
        if(annualCaId == 3) {
          this.total += this.private_organization_low_price;
        } else if (annualCaId == 4) {
          this.total += this.private_organization_high_price;
        } else {
          return 'error';
        }
        break;
      default : 
        ('problemo');
        return 'error';
    }

    const adhesionCost = this.total + 0;

    let promoCodeHasBeenTested = false;
    if(promoCode && this.promoCodeValidated != promoCode) {
      const res:any = await this.checkPromoCode(promoCode, 'adhesion_moral', 0);
      if(res.msg !== 'success') {
        alert(res.msg);
      } else {
        this.total = 0;
        this.promoCodeValidated = promoCode;
        promoCodeHasBeenTested = true;
      }
    } else if(promoCode && this.promoCodeValidated === promoCode) {
      this.total = 0;
    }  
    let total = this.total + 0;
    if(numberOfExtraAdhesion) {
      total = this.addExtraAdhesion(numberOfExtraAdhesion);
    }

    return {adhesionCost, total, promoCodeHasBeenTested: promoCodeHasBeenTested};

  }

  addExtraAdhesion(number) {
    let total  = this.total + 0;
    total += number * this.regular_price;
    return total;
  }

  getExtraAdhesionAmount(number) {
    return number * this.regular_price;
  }

  checkPromoCode(promoCode, type, shouldDecrement = 1) {
    return new Promise(resolve => {
      const url = apiURL('promo_code', 'use', '');
      ajax(url, 'POST', {id_: promoCode, type, shouldDecrement} , (res: any) => {
        // console.log(res);
        const result = JSON.parse(res).records;
        resolve(result);
      }, false);
    }); 
  }

  generateBillNumber(item, infos) {
    const date = new Date();
    let month, day, year, randChars;

    if(item === 'adhesion_moral' || item === 'adhesion_physic') {
      year = date.getFullYear();
      const month_ = date.getMonth() + 1;
      const day_ = date.getDate();
      
      month = month_ < 10 ? '0' + month_ : month_;
      day = day_ < 10 ? '0' + day_ : day_;
      
      randChars = randomChars(5); 
    }

    switch(item) {
      case 'congress' :
        break;
      case 'adhesion_physic' :
        let infos_name = infos.name.split('').map(char => {
          if(char.match(/[Êéè]/)){
            char = 'e';
          } else if(!char.match(/[A-Za-z]/)) {
            char = '0';
          }
          return char;
        }).join('');  
        return `F-A-${year}-${month}-${day}-${infos_name}-${randChars}`;
      case 'adhesion_moral' : 
        return `F-AM-${year}-${month}-${day}-${randChars}`;
    }
  }


  paymentHandler(billing_number, amount, user_mail, user_civility, user_firstname, user_name) {
    return new Promise(resolve => {
      const url = apiURL('-monetico', '-paiement-handler', '');
      ajax(url, 'POST', {billing_number, amount, user_mail, user_civility, user_firstname, user_name} , (res: any) => {
       
        const result = JSON.parse(res).records;
        resolve(result);
      }, false, error => { (error) });
    }); 
  }
}
