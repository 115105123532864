import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ajax, apiURL } from '../../../providers/helpers';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-filters-popup',
  templateUrl: './filters-popup.component.html',
  styleUrls: ['./filters-popup.component.css']
})
export class FiltersPopupComponent implements OnInit {

  @Input() table_names: {name: string, val: string}[];
  @Input() page_name: string;
  @Output() output        = new EventEmitter<{}>();
  @Output() cancelFilter  = new EventEmitter<{}>();
  @Output() close         = new EventEmitter<{}>();

  faTimes = faTimes;

  filters = {};
  selectedFilterTableName: string;
  selectedFilterOptions = [];

  filterPropName: string;
  filterPropValues = [];

  thematics: [];

  constructor() { }

  closePopup() {
    this.close.emit();
  }

  onChange() {
    this.selectedFilterOptions = this.filters[this.selectedFilterTableName];
  }

  filter() {
    const prop_values = this.selectedFilterOptions.filter(option => option.selected);
    const prop_name   = this.selectedFilterTableName;
    window.sessionStorage.SFSE_filter_prop_values = JSON.stringify(prop_values);
    window.sessionStorage.SFSE_filter_prop_name   = prop_name;    
    window.sessionStorage.SFSE_filter_page        = this.page_name;    

    this.output.emit({prop_values, prop_name});
    this.closePopup();
  }

  ngOnInit() {
    this.table_names.forEach(table_name => {
      const url = apiURL(table_name.val, 'lire', '');
      ajax(url, 'GET', null, (res: any) => {
        let options = JSON.parse(res).records || [];
        options = options.map(option => {
          option.selected = false;
          return option;
        });
        this.filters[table_name.val] = options;
      }, false);
    })
  }

}
