import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

// ANALYTICS WITH MATOMO
// import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
// import { NgxMatomoRouterModule } from '@ngx-matomo/router';

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { AngularEditorModule } from "@kolkov/angular-editor";
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";

// Captcha Module
// import { BotDetectCaptchaModule } from 'angular-captcha';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaModule,
  RecaptchaSettings,
} from "ng-recaptcha";
const globalRecaptchaSettings: RecaptchaSettings = {
  siteKey: "6LfJjScaAAAAAO7Z4H8XTZJznQTW88yx9SDVxzr5",
};

// pipes
import { FrenchDateFormat } from "./pipes/french-date-format-pipe";
import { TruncatePipe } from "./pipes/truncatePipe";

// main nav items
import { ActionsMainComponent } from "./views/actions/main/main.component";
import { HomeComponent } from "./views/home/home.component";
import { MembershipMainComponent } from "./views/membership/main/main.component";
import { PresentationMainComponent } from "./views/presentation/main/main.component";
import { RdMainComponent } from "./views/rd/main/main.component";

//Présentation
import { AdherentsComponent } from "./views/presentation/adherents/adherents.component";
import { PopupAdherentMoralDetailsComponent } from "./views/presentation/adherents/popup-adherent-moral-details/popup-adherent-moral-details.component";
import { AssembleesComponent } from "./views/presentation/assemblees/assemblees.component";
import { BilansComponent } from "./views/presentation/bilans/bilans.component";
import { OrganisationComponent } from "./views/presentation/organisation/organisation.component";
import { PartenairesComponent } from "./views/presentation/partenaires/partenaires.component";
import { QuisommesnousComponent } from "./views/presentation/quisommesnous/quisommesnous.component";

import { ContactComponent } from "./views/presentation/contact/contact.component";
import { SoutenirComponent } from "./views/presentation/soutenir/soutenir.component";

//Nos actions
import { DocumentaryCenterComponent } from "./views/actions/documentary-center/documentary-center.component";
import { EnvironmentJournalComponent } from "./views/actions/environment-journal/environment-journal.component";
import { WorkSectionDetailsComponent } from "./views/actions/work-section-details/work-section-details.component";
import { WorkSectionsComponent } from "./views/actions/work-sections/work-sections.component";

//Congrès
import { AwardsComponent } from "./views/congreses/awards/awards.component";
import { CommunicationComponent } from "./views/congreses/communication/communication.component";
import { DocFoundComponent } from "./views/congreses/doc-found/doc-found.component";
import { InfosComponent } from "./views/congreses/infos/infos.component";
import { OrganizationComponent } from "./views/congreses/organization/organization.component";
import { PartnersComponent } from "./views/congreses/partners/partners.component";
import { PresentationComponent } from "./views/congreses/presentation/presentation.component";
import { ReservationComponent } from "./views/congreses/reservation/reservation.component";

import { ActsComponent } from "./views/congreses/acts/acts.component";
import { AwardsListComponent } from "./views/congreses/awards-list/awards-list.component";
import { CongresesListComponent } from "./views/congreses/congreses-list/congreses-list.component";
import { InterviewsComponent } from "./views/congreses/interviews/interviews.component";
import { PresentationsListComponent } from "./views/congreses/presentations-list/presentations-list.component";

import { FilterWithThematicComponent } from "./views/rd/filter-with-thematic/filter-with-thematic.component";

//Adhesion
import { AdhesionMoraleComponent } from "./views/membership/adhesion-morale/adhesion-morale.component";
import { AdhesionPhysiqueComponent } from "./views/membership/adhesion-physique/adhesion-physique.component";
import { AnnuaireComponent } from "./views/membership/annuaire/annuaire.component";
import { CongressResumeComponent } from "./views/membership/congress-resume/congress-resume.component";
import { CongressSignupComponent } from "./views/membership/congress-signup/congress-signup.component";
import { DonnationComponent } from "./views/membership/donnation/donnation.component";
// import { NewsletterComponent } from './views/membership/newsletter/newsletter.component';
import { MyThinkSectionDocsComponent } from "./views/membership/my-think-sections/my-think-section-docs/my-think-section-docs.component";
import { MyThinkSectionsListComponent } from "./views/membership/my-think-sections/my-think-sections-list/my-think-sections-list.component";
import { ProfilComponent } from "./views/membership/profil/profil.component";

// Templates
import { AsideBtnsComponent } from "./views/congreses/aside-btns/aside-btns.component";
import { CongressLinkBtnComponent } from "./views/congreses/congress-link-btn/congress-link-btn.component";
import { NavbarComponent } from "./views/navbar/navbar.component";
import { AsideNavComponent } from "./views/template/aside-nav/aside-nav.component";
import { FilteredByThematicsComponent } from "./views/template/filtered-by-thematics/filtered-by-thematics.component";
import { FooterComponent } from "./views/template/footer/footer.component";
import { MainTemplateComponent } from "./views/template/main/main.component";
import { PaginationComponent } from "./views/template/pagination/pagination.component";
import { PdfViewerComponent } from "./views/template/pdf-viewer/pdf-viewer.component";

import { AdhesionMoralFormComponent } from "./views/adhesionform/adhesion-moral-form/adhesion-moral-form.component";
import { AdhesionPhysicFormComponent } from "./views/adhesionform/adhesion-physic-form/adhesion-physic-form.component";
import { PopupAdherentDetailsComponent } from "./views/membership/popup-adherent-details/popup-adherent-details.component";
import { AddNewItemFormComponent } from "./views/template/add-new-item-form/add-new-item-form.component";
import { AutocompleteFieldComponent } from "./views/template/autocomplete-field/autocomplete-field.component";
import { FiltersPopupComponent } from "./views/template/filters-popup/filters-popup.component";
import { LoginPopupComponent } from "./views/template/login-popup/login-popup.component";
import { PopupComponent } from "./views/template/popup/popup.component";
import { PostActionsDetailsComponent } from "./views/template/posts/post-actions-details/post-actions-details.component";
import { PostDetailComponent } from "./views/template/posts/post-detail/post-detail.component";
import { PostsActionsComponent } from "./views/template/posts/posts-actions/posts-actions.component";
import { PostsComponent } from "./views/template/posts/posts/posts.component";
import { SelectFieldComponent } from "./views/template/select-field/select-field.component";
import { SelectMultiComponent } from "./views/template/select-multi/select-multi.component";
import { UserThinkSectionFieldComponent } from "./views/template/user-think-section-field/user-think-section-field.component";
import { ThematicSearchComponent } from "./views/thematic-search/thematic-search.component";
import { TehmaticSearchDetailsComponent } from "./views/thematicSearch/details/tehmatic-search-details/tehmatic-search-details.component";

import { CongressDevisFormPopupComponent } from "./views/congreses/congress-devis-form-popup/congress-devis-form-popup.component";
import { AdhesionMoraleDevisFormPopupComponent } from "./views/membership/adhesion-morale-devis-form-popup/adhesion-morale-devis-form-popup.component";

import { PopupSignupComponent } from "./views/congreses/popup-signup/popup-signup.component";

import { LoaderComponent } from "./views/template/loader/loader.component";
import { PhoneField } from "./views/template/phone-field/phone-field.component";
import { TransitionPageComponent } from "./views/transition-page/transition-page.component";

// import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
// import { NgxMatomoRouterModule } from '@ngx-matomo/router';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PostDetailComponent,
    PostsComponent,
    HomeComponent,
    PresentationMainComponent,
    ActionsMainComponent,
    RdMainComponent,
    MembershipMainComponent,
    MainTemplateComponent,
    AsideNavComponent,
    QuisommesnousComponent,
    OrganisationComponent,
    BilansComponent,
    AssembleesComponent,
    PartenairesComponent,
    AdherentsComponent,
    PopupAdherentMoralDetailsComponent,
    ContactComponent,
    SoutenirComponent,
    EnvironmentJournalComponent,
    DocumentaryCenterComponent,
    WorkSectionsComponent,
    PresentationComponent,
    OrganizationComponent,
    CommunicationComponent,
    ReservationComponent,
    AwardsComponent,
    PartnersComponent,
    DocFoundComponent,
    InfosComponent,
    PdfViewerComponent,
    PaginationComponent,
    FilteredByThematicsComponent,
    FilterWithThematicComponent,
    FooterComponent,
    AsideBtnsComponent,
    CongressLinkBtnComponent,
    CongresesListComponent,
    PresentationsListComponent,
    InterviewsComponent,
    ActsComponent,
    AwardsListComponent,
    WorkSectionDetailsComponent,
    TruncatePipe,
    FrenchDateFormat,
    PostsActionsComponent,
    PostActionsDetailsComponent,
    LoginPopupComponent,
    AutocompleteFieldComponent,
    SelectMultiComponent,
    PopupComponent,
    AnnuaireComponent,
    CongressSignupComponent,
    CongressResumeComponent,
    DonnationComponent,
    // NewsletterComponent,
    ProfilComponent,
    MyThinkSectionsListComponent,
    MyThinkSectionDocsComponent,
    AdhesionPhysiqueComponent,
    AdhesionMoraleComponent,
    AdhesionMoralFormComponent,
    AdhesionMoraleDevisFormPopupComponent,
    AdhesionPhysicFormComponent,
    PopupAdherentDetailsComponent,
    FiltersPopupComponent,
    ThematicSearchComponent,
    TehmaticSearchDetailsComponent,
    AddNewItemFormComponent,
    SelectFieldComponent,
    UserThinkSectionFieldComponent,
    CongressDevisFormPopupComponent,
    PopupSignupComponent,
    TransitionPageComponent,
    LoaderComponent,
    PhoneField,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    PdfJsViewerModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    //BotDetectCaptchaModule
    // NgxMatomoTrackerModule.forRoot({
    //   siteId: 'YOUR_MATOMO_SITE_ID', // your Matomo's site ID (find it in your Matomo's settings)
    //   trackerUrl: 'http://localhost:3306/', // your matomo server root url
    // }),
    // NgxMatomoRouterModule,
    RecaptchaModule,
    // NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://sfse.matomo.cloud/', siteId: 'sfse.org' }),
    // NgxMatomoRouterModule
  ],

  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: globalRecaptchaSettings,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
