import { GLOBALS } from '../providers/helpers';

export default class StorageService {

    storageId;

    constructor() {
        this.storageId = GLOBALS.STORAGE_ID;
    } 

    get() {
        let data = window.sessionStorage[this.storageId];
        if (data) {
            return JSON.parse(data);
        } 

        data = {
            navData: null,
            currentNavItemIndex: null,
            currentNavSectionIndex: null,
            inAppNavigation: false,
            user: null,
        
            congress: null,
            sideNav2: false
        }

        window.sessionStorage[this.storageId] = JSON.stringify(data);
        return data;
    }

    setItem(itemKey, itemVal) {
        const data = this.get();
        data[itemKey] = itemVal;
        window.sessionStorage[this.storageId] = JSON.stringify(data);
        return itemVal;
    }

    getItem(itemKey) {
        return this.get()[itemKey];
    }
}
