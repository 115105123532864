import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class PresentationMainComponent implements OnInit {

  
  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
