import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../../providers/helpers';
import { AG_MODELE_PROCURATION_PDF_NAME } from '../../../providers/global';


@Component({
  selector: 'app-assemblees',
  templateUrl: './assemblees.component.html',
  styleUrls: ['./assemblees.component.css']
})
export class AssembleesComponent implements OnInit {

  constructor() { }

  navItems: [];
  assemblees = [];
  pdf_id = null;

  showPdf() {
    const url = apiURL('pdf', 'read_one_id', '');

    ajax(url, 'POST', {name: AG_MODELE_PROCURATION_PDF_NAME}, res => {
      let response: any = res;
      (response);
      response = JSON.parse(response);
      this.pdf_id = response.records.id;
    }, false);
  }

  ngOnInit() {
    window.scroll(0,0);
    const assembleeUrl = apiURL('general_assembly', 'lire', '');
    ajax(assembleeUrl, 'GET', null, res => {
      const response = JSON.parse(res);
      if(!response.records) {return;} 
      const assemblees = response.records;
      this.assemblees = assemblees.map((assemblee, index) => {
        assemblee.index = index;
        return assemblee;
      });

    }, false);
  }

}
