import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  ENV,
  GLOBALS,
  ajax,
  apiURL,
  randomChars,
  resizeImage,
  timestampToDate,
} from "../../../providers/helpers";
import { PdfGeneratorService } from "../../../providers/pdf-generator.service";
import { PurchaseService } from "../../../providers/purchase.service";

import { Router } from "@angular/router";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import User from "../../../models/User";
import {
  ERS_SUBSCRIPTION_AMOUNT,
  IMG_FOLDER_USER_ID,
  REGLEMENT_INTERIEUR_PDF_NAME,
  REGULAR_SIGNUP_PRICE,
  STATUS_PDF_NAME,
  STUDENT_SIGNUP_PRICE,
  USER_ROLE_ADHERENT_ID,
  USER_ROLE_NON_ADHERENT_ID,
} from "../../../providers/global";
import {
  getStoredUser,
  updateStoredUser,
} from "../../../providers/user-service";

@Component({
  selector: "app-adhesion-physic-form",
  templateUrl: "./adhesion-physic-form.component.html",
  styleUrls: ["./adhesion-physic-form.component.css"],
})
export class AdhesionPhysicFormComponent implements OnInit {
  @Output() close = new EventEmitter<{}>();

  faArrowLeft = faArrowLeft;

  env = ENV;
  pdf_id;
  reglementInterieurPdfName = REGLEMENT_INTERIEUR_PDF_NAME;
  statusPdfName = STATUS_PDF_NAME;
  showPopup;
  popupText: string;
  popupAction: any = null;
  newPdfSrc;

  adhesionForm;
  adhesionPage = 1;
  statuses = [];
  countries = [];
  organizationCategories = [];
  country_code;
  competences = [];
  pwdIsValid;
  pwdsAreTheSame;
  userOrganizationName: string;
  userOrganizationCategoryName: string;
  isValidMail = true;
  thematics = [];
  thematicsSub = [];
  selectedThematicsSub = [];
  selectedThematicId;
  thinkSections: any = null;
  imgFormData;
  formIsComplete = false;
  userHasChosenActivityDomain = false;
  userHasCheckedAllThinkSections = false;
  lastPageText: any = "";
  loading = false;
  user: User;
  cellphone: string;
  phone: string;

  order: any = {
    ersAmount: 0,
    adhesionAmount: 0,
    promoCode: "",
    pricing: "",
    date: null,
    signupPrice: null,
  };

  chosenImg;
  chosenImgSrc;
  resizedImg;
  resizedImgSrc;
  paiementData: any = {};
  moneticoHref = "";
  showMoneticoExternalLink = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private purchaseService: PurchaseService,
    private pdfGeneratorService: PdfGeneratorService
  ) {
    this.user = new User(getStoredUser());
    this.cellphone = this.user.cellphone || "";
    this.phone = this.user.phone || "";

    const adhesionForm = {
      civility_id: this.user.civility_id || "",
      status_id: this.user.status_id || "",
      country_id: this.user.country_id || "",
      firstname: this.user.firstname || "",
      name: this.user.name || "",
      mail: this.user.mail || "",
      mailConfirm: this.user.mail || "",
      pwd: "",
      pwdConfirm: "",
      // phone: "",
      // cellphone: this.user.cellphone || "",
      code_promo: "",
      mail_pro: "",
      activity_domain: null,
      category_is_correct: "1",
      organization_2: "",
      full_address: "",
      competence_id: "",
      competence2_id: "",
      competence3_id: "",
      occupied_function: "",
      linkedin: "",
      ers_paper: "0",
      delivery_address: "",
      is_visible: "1",
      accepts_cgu: false,
      billing_address: "",
      billing_city: "",
      billing_postal_code: "",
      hacked_paiement: "",
      organization_subtitle: "",
      // newsletter: 'none'
    };

    for (const key in adhesionForm) {
      // if(key === "newsletter") {
      //   adhesionForm[key] = "none";
      // } else
      if (this.user[key]) {
        adhesionForm[key] = this.user[key];
      }
    }

    this.adhesionForm = this.formBuilder.group(adhesionForm);
  }

  emitPhone($event, propName) {
    this[propName] = $event;
  }

  setImgToUpload(e) {
    this.chosenImg = e.target.files[0];

    const reader: FileReader = new FileReader();
    reader.readAsDataURL(this.chosenImg);

    reader.onloadend = (e: any) => {
      this.chosenImgSrc = reader.result;

      resizeImage(e, this.chosenImg.name, this.chosenImg.type, 0.2, 50).then(
        (resizedImg: File) => {
          this.resizedImg = resizedImg;

          reader.onloadend = () => {
            this.resizedImgSrc = reader.result;
          };
        }
      );
    };
  }

  openPdf(pdf_name) {
    const pdfURL = apiURL("pdf", "read_one_id", "");
    ajax(
      pdfURL,
      "POST",
      { name: this[pdf_name] },
      (res) => {
        this.pdf_id = JSON.parse(res).records.id;
      },
      false
    );
  }

  onSubmitPDF(e) {
    e.preventDefault();

    const formData = new FormData(e.target);

    const pdfURL = apiURL("pdf", "create", "");
    ajax(
      pdfURL,
      "POST",
      formData,
      (res) => {
        const pdfURL = apiURL("pdf", "read_one", JSON.parse(res).newPdfId);
        ajax(
          pdfURL,
          "GET",
          null,
          (newPDF) => {
            newPDF = JSON.parse(newPDF);
            newPDF.originalName = newPDF.name;
            this.newPdfSrc = null;
          },
          false
        );
      },
      true
    );
  }

  selectedCountryHasChanged(event) {
    const index = this.countries.findIndex(
      (country) => country.id == event.target.value
    );
    this.user.country_name = this.countries[index].name;
    this.country_code = this.countries[index].country_code;
  }

  updateOrCreateAccount(queryMethod, data, success) {
    if (queryMethod === "create") {
      this.createAccount();
      return;
    }

    const url = apiURL("user", "update", this.user.id);
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        this.popupText = "Vos informations ont été mises à jour !";
        if (this.adhesionPage == 2 && this.adhesionForm.value.code_promo) {
          this.popupText +=
            "<p>Votre code promotionnel a bien été pris en compte.</p>";
        }
        this.showPopup = true;
        success(res);
      },
      false,
      (error: any) => {
        this.adhesionPage--;
        // console.log(error);
        const err = JSON.parse(error);
        this.popupText = err.message;
        this.showPopup = true;
      }
    );
  }

  createAccount() {
    const userData = { ...this.adhesionForm.value, cellphone: this.cellphone };
    userData.organization_id = this.user.organization_id;
    const url = apiURL("user", "create", this.user.id);
    ajax(
      url,
      "POST",
      userData,
      (res: any) => {
        const userId = JSON.parse(res).records;
        this.user.id = userId;
        this.popupText =
          "Votre compte utilisateur relié à l'adresse mail " +
          this.adhesionForm.value.mail +
          " a bien été créé !";
        this.showPopup = true;
        userData.role_id = USER_ROLE_NON_ADHERENT_ID;
        userData.role_name = "Non-adhérent";
        const storedUserData = { ...userData, id: userId };
        if (this.adhesionPage == 2 && this.adhesionForm.value.code_promo) {
          this.popupText +=
            "<p>Votre code promotionnel a bien été pris en compte.</p>";
        }
        this.showPopup = true;
        updateStoredUser(storedUserData);
        this.sendConfirmSignupMail();
      },
      false,
      (error: any) => {
        this.adhesionPage--;
        const err = JSON.parse(error);
        this.popupText = err.existingMail
          ? "Un compte est déjà rattaché à cette adresse mail ! Pour adhérer ou ré-adhérer, connectez-vous d’abord pour poursuivre la procédure en ligne"
          : err.message;
        this.showPopup = true;
      }
    );
  }

  sendConfirmSignupMail() {
    const mail = this.adhesionForm.value.mail;
    const mailObj = {
      addresses: mail,
      body: `<h3>Félicitations ! Vous êtes désormais inscrit au site de la SFSE.</h3><p>Votre adresse mail : ${mail} vous servira d'identifiant.</p>`,
      subject: "Bienvenue à la SFSE",
    };
    const url = apiURL("-mailer_service", "phpmailer", "");
    ajax(
      url,
      "POST",
      mailObj,
      (res: any) => {
        // console.log('mail envoyé !');
      },
      false,
      (error: any) => {
        this.popupText = JSON.parse(error).message;
        this.showPopup = true;
      }
    );
  }

  canGoToPage3() {
    const errors = [];
    if (!this.userHasChosenActivityDomain) {
      errors.push("Au moins un domaine d'activité");
    }

    if (!this.adhesionForm.value.competence_id) {
      errors.push("Votre domaine de compétence principal");
    }

    if (this.selectedThematicsSub.length < 1) {
      errors.push("Au moins une thématique d'intérêt");
    }

    if (errors.length > 0) {
      this.popupText =
        "<h2>Liste des champs à remplir ou à corriger</h2>" +
        errors.join("<br>");
      this.showPopup = true;
    }

    return errors.length === 0;
  }

  async canGoToPage2() {
    const errors = [];
    if (!this.adhesionForm.value.civility_id) {
      errors.push("Votre civilité (M./Mme)");
    }
    if (!this.adhesionForm.value.status_id) {
      errors.push("Votre statut (étudiant, actif, retraité ...)");
    }
    if (
      !this.userOrganizationName &&
      !this.user.organization_id &&
      this.adhesionForm.value.status_id != 5
    ) {
      errors.push("Votre organisme principal de rattachement");
    }
    if (!this.adhesionForm.value.country_id) {
      errors.push("Votre pays");
    }
    if (!this.user.id && (!this.pwdIsValid || !this.pwdsAreTheSame)) {
      errors.push("Votre mot de passe");
    }
    if (!this.adhesionForm.value.firstname) {
      errors.push("Votre prénom");
    }
    if (!this.adhesionForm.value.name) {
      errors.push("Votre nom");
    }
    if (
      !this.isValidMail ||
      this.adhesionForm.value.mail !== this.adhesionForm.value.mailConfirm
    ) {
      errors.push("Votre adresse mail");
    }

    if (errors.length > 0) {
      this.popupText =
        "<h2>Liste des champs à remplir ou à corriger</h2>" +
        errors.join("<br>");
      this.showPopup = true;
    }

    return errors.length === 0;
  }

  async goNextPage() {
    document.querySelector(".adhesion-popup").scrollTop = 0;
    if (this.adhesionPage === 1 && !this.canGoToPage2()) {
      return;
    }

    if (this.adhesionPage === 2 && !this.canGoToPage3()) {
      return;
    }

    if (this.adhesionPage === 3) {
      if (!this.checkIfUserHasCheckedAllThinkSections()) {
        this.popupText =
          "Vous devez choisir (à nouveau) si vous souhaitez participer ou non à chaque section de réflexion.";
        this.showPopup = true;
        return;
      }
      // if (this.adhesionForm.value.newsletter === "none") {
      //   this.popupText =
      //     "Vous devez choisir (à nouveau) si vous souhaitez ou non recevoir la newsletter de la SFSE.";
      //   this.showPopup = true;
      //   return;
      // }
      if (
        this.adhesionForm.value.ers_paper == "1" &&
        !this.adhesionForm.value.delivery_address
      ) {
        this.popupText = "Vous devez saisir une adresse de livraison.";
        this.showPopup = true;
        return;
      }

      this.updateUserThinkSections();
    }

    if (this.adhesionPage === 4) {
      if (!this.adhesionForm.value.accepts_cgu) {
        this.popupText =
          "Vous devez accepter les statuts et le règlement intérieur.";
        this.showPopup = true;
      } else if (!this.adhesionForm.value.billing_address) {
        this.popupText = "Vous devez saisir une adresse de facturation.";
        this.showPopup = true;
      } else if (
        this.adhesionForm.value.billing_address.length > 50 ||
        this.adhesionForm.value.billing_postal_code.length > 50 ||
        this.adhesionForm.value.billing_city.length > 50
      ) {
        this.popupText =
          "Votre adresse de facturation ne doit pas dépasser 50 caractères par ligne (adresse, ville, code postal).";
        this.showPopup = true;
      } else {
        if (this.order.ersAmount === 0 && this.order.adhesionAmount === 0) {
          this.setUserRoleToAdherent(false, 1);
          const lastPageText = await this.setUpLastPageText(
            "Votre adhésion à titre gratuit est effective. Un mail de confirmation vous a été envoyé. Vous pouvez poursuivre votre navigation sur notre site en tant qu'adhérent(e) SFSE !"
          );
          this.lastPageText = lastPageText;
          this.loadPaiementForm();
        } else {
          // this.popupAction = "finalizePaiement";
          // this.popupText = `Attention : Le paiement en ligne se fait sur une plateforme extérieure à notre site internet. Votre navigateur va donc essayer d’ouvrir une nouvelle fenêtre en POP-UP. Il se peut que vos paramètres bloquent l’ouverture du POP-UP. Si tel était le cas, un message discret s’affichera alors en haut de votre navigateur pour vous inviter à autoriser l’ouverture du POP-UP depuis le site de la SFSE. Il est important d’autoriser cette manipulation.`;
          // this.showPopup = true;
          this.loadPaiementForm();
          this.adhesionPage++;
          const lastPageText = await this.setUpLastPageText(
            "Si vous n’avez pas été redirigé(e) vers le formulaire de paiement, vous pouvez cliquer sur le lien ci-dessous. Dans le cas contraire, vous pouvez simplement quitter le site et vous connecter en tant qu'adhérent(e), une fois votre paiement effectué."
          );
          this.lastPageText = lastPageText;
        }
      }
      return;
    }

    // this.popupCtnr.nativeElement.To(0,0);
    if (
      this.adhesionPage === 1 &&
      !this.user.organization_id &&
      this.adhesionForm.value.status_id != 5
    ) {
      const url = apiURL("organization", "create", "");
      ajax(
        url,
        "POST",
        { name: this.userOrganizationName },
        (res: any) => {
          this.user.organization_id = JSON.parse(res).records.id;
          this.updateUser();
          this.adhesionPage++;
          if (this.competences.length < 1) {
            this.loadCompetences();
          }
        },
        false
      );
      return;
    }

    if (this.adhesionPage === 1 && this.competences.length < 1) {
      this.loadCompetences();
    }

    if (this.adhesionPage === 1 && this.thematics.length < 1) {
      this.loadThematics();
    }

    if (
      (this.adhesionPage === 1 ||
        (this.adhesionPage === 3 && this.user.role_id == "3")) &&
      this.adhesionForm.value.code_promo
    ) {
      const res: any = await this.purchaseService.checkPromoCode(
        this.adhesionForm.value.code_promo,
        "adhesion_physic",
        0
      );
      this.popupText = "";
      if (res.msg !== "success") {
        this.popupText =
          "<p>Votre code de promotion n'a pas pu être pris en compte.<p>";
        this.adhesionForm.controls["code_promo"].setValue("");
        this.showPopup = true;
        return;
      }
    }

    if (this.adhesionPage === 2) {
      const userThematicsSubData = {
        thematic_sub_ids: this.selectedThematicsSub
          .map((item) => item.id)
          .join(","),
        user_id: this.user.id,
      };
      const userThematicsSubUrl = apiURL("user_thematic_sub", "update", "");
      ajax(
        userThematicsSubUrl,
        "POST",
        userThematicsSubData,
        (res: any) => {},
        false
      );
    }

    this.updateUser();
    this.adhesionPage++;
    const hasLowPricing = this.adhesionForm.value.status_id != "1";
    if (this.adhesionPage === 4) {
      if (hasLowPricing) {
        this.order.pricing = "réduit";
        this.order.signupPrice = STUDENT_SIGNUP_PRICE;
      } else {
        this.order.pricing = "normal";
        this.order.signupPrice = REGULAR_SIGNUP_PRICE;
      }

      this.purchaseService
        .calculatePhysicAdhesionAmount(
          hasLowPricing,
          this.adhesionForm.value.ers_paper === "1",
          this.adhesionForm.value.code_promo
        )
        .then(
          (response: {
            adhesionAmount: Number;
            status: String;
            ersAmount: Number;
          }) => {
            if (response.status === "error") {
              this.popupText =
                "Votre code de promotion n'a pas pu être pris en compte.";
              this.showPopup = true;
            } else {
              if (this.adhesionForm.value.code_promo) {
                this.order.pricing = "réduit";
                this.order.promoCode = this.adhesionForm.value.code_promo;
              }
            }
            this.order.ersAmount = response.ersAmount;
            this.order.adhesionAmount = response.adhesionAmount;
          }
        );
    }
    document.querySelector(".adhesion-popup").scrollTop = 0;
  }

  createUserImg(img_id) {
    this.user.img_id = img_id;
    this.user.implement(this.adhesionForm.value);
    const userData = {
      ...this.adhesionForm.value,
      id: this.user.id,
      organization_id: this.user.organization_id,
      img_id,
    };

    this.updateOrCreateAccount("update", userData, () => {
      // console.log(this.user);
      updateStoredUser(this.user);
      this.chosenImg = null;
      this.resizedImg = null;
      this.chosenImgSrc = null;
    });
  }

  updateUser() {
    // console.log(this.user)
    this.user.implement(this.adhesionForm.value);
    const queryMethod =
      this.adhesionPage === 1 && !this.user.id ? "create" : "update";
    const userData = {
      ...this.adhesionForm.value,
      id: this.user.id,
      organization_id: this.user.organization_id,
    };

    if (this.adhesionPage === 2) {
      userData.phone = this.phone;
    } else if (this.adhesionPage === 1) {
      userData.cellphone = this.cellphone;
    }

    this.updateOrCreateAccount(queryMethod, userData, (res) => {
      if (queryMethod === "update") {
        // console.log(this.user)
        updateStoredUser(this.user);
      }
    });
  }

  setUpLastPageText(text) {
    return new Promise((resolve) => {
      resolve(text);
    });
  }

  setUserRoleToAdherent(shouldReload = false, is_free = null) {
    const data = {
      id: this.user.id,
      is_free,
      promo_code: this.order.promoCode,
    };
    const url = apiURL("user", "update_role_to_adherent", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        // console.log(res);
        this.user.role_id = String(USER_ROLE_ADHERENT_ID);
        this.user.role_name = "Adhérent";
        updateStoredUser(this.user);
        // console.log(this.user);
        if (shouldReload) {
          window.location.reload();
        }
      },
      false
    );
  }

  goPreviousPage() {
    this.adhesionPage--;
  }

  closePopup() {
    this.close.emit();
    if (this.formIsComplete == true) {
      this.router.navigate(["/accueil"]);
      window.location.reload();
    }
  }

  onSubmitAdhesionForm(e) {
    // this.loginForm.reset();
    e.preventDefault();
    if (this.chosenImg && this.chosenImg.type && this.resizedImg) {
      this.addUserImage(e);
      this.goNextPage();
    }

    console.warn("Connexion en cours ...", e.target);
  }

  addUserImage(e) {
    const formData = new FormData(e.target);
    formData.append("folderId", String(IMG_FOLDER_USER_ID));
    formData.append("type", this.chosenImg.type);
    formData.append("img", this.resizedImg);

    const imgURL = apiURL("img", "create", "");
    ajax(
      imgURL,
      "POST",
      formData,
      (res) => {
        this.createUserImg(JSON.parse(res).imgId);
        // console.log('image ajoutée !');
      },
      true
    );
  }

  passwordIsValid(pwd, validPropName) {
    if (pwd.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      this[validPropName] = true;
    } else {
      this[validPropName] = false;
    }
  }

  mailIsValid(mail, validPropName) {
    if (mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      this[validPropName] = true;
    } else {
      this[validPropName] = false;
    }
  }

  textsAreIdenticals(text1, text2, validPropName) {
    if (text1 === text2) {
      this[validPropName] = true;
    } else {
      this[validPropName] = false;
    }
  }

  setOrganizationSuggestion(evt) {
    this.userOrganizationCategoryName = evt.cat_name;
    this.userOrganizationName = evt.name;
    this.user.organization_id = evt.id;
  }

  loadCompetences() {
    const url = apiURL("competence", "read_without_data", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        const competences = JSON.parse(res).records;
        competences.forEach((competence, i) => {
          if (
            competences[i - 1] &&
            competences[i - 1].group_number !== competence.group_number
          ) {
            this.competences.push({ name: "", disabled: true });
          }
          this.competences.push(competence);
        });
      },
      false
    );
  }

  loadThematics() {
    const url = apiURL("thematic", "read", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.thematics = JSON.parse(res).records || [];
      },
      false
    );
  }

  loadThematicsSub(thematic_id) {
    if (this.selectedThematicId === thematic_id) {
      this.selectedThematicId = "";
      return;
    }
    this.selectedThematicId = thematic_id;
    const url = apiURL("thematic_sub", "read_by_thematic", thematic_id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.thematicsSub = JSON.parse(res).records || [];
      },
      false
    );
  }

  selectSubThematic(thematic) {
    if (
      this.selectedThematicsSub.findIndex((item) => item.id == thematic.id) < 0
    ) {
      this.selectedThematicsSub.push(thematic);
    }
  }

  clearThematicsSub() {
    this.selectedThematicsSub = [];
  }

  closePopupAndWindow() {
    this.showPopup = null;
    if (this.formIsComplete == true) {
      this.closePopup();
    }
  }

  sendMail(pdfsData = null, noPaiement) {
    let addComptaInCopy, isSentByCompta;
    const subject = "Votre adhésion Personne physique à la SFSE";
    let addresses = `${this.user.mail}`;
    if (this.env == "prod" && !noPaiement) {
      // addresses += `,${SFSE_COMPTABILITE_MAIL}`;
      addComptaInCopy = false;
      isSentByCompta = true;
    }
    const data: any = {
      body: "adhesion_physique",
      subject,
      addresses,
      pdfDatas: pdfsData,
      pdfNames: "recu_fiscal,facture",
      addComptaInCopy,
      isSentByCompta,
    };
    const url = apiURL("-mailer_service", "phpmailer", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        this.loading = false;
        if (!noPaiement) {
          // faire apparaitre un popup d'information comme quoi il faudra se reconnecter a la fin du paiement
          this.lastPageText =
            "Pour finaliser votre paiement, vous serez redirrigé.e sur le service Monetico. Un mail vous sera ensuite envoyé confirmant votre adhésion à la SFSE. Vous devrez alors vous déconnecter puis vous reconnecter sur le site pour que les modifications liées à l'adhésion soient prises en considération.";
        } else {
          // this.createAdhesionPhysicWithNoPaiement();
          this.popupText = `Vous êtes à présent déconnecté(e) ! Votre adhésion à titre gratuit a bien été prise en compte. Lors de votre prochaine connexion, vous aurez le statut d'adhérent(e).`;
          this.setUpUserBeforeLogout();
          this.showPopup = true;
          // setTimeout(() => {
          //   window.location.reload();
          // }, 4000);
        }
        this.formIsComplete = true;
        this.showPopup = true;
        // console.log(noPaiement, '3', this.showPopup, this.popupText);
      },
      false,
      (err) => {
        this.popupText = `Vous êtes à présent déconnecté(e) ! Votre adhésion à titre gratuit a bien été prise en compte. Lors de votre prochaine connexion, vous aurez le statut d'adhérent(e).`;
        this.setUpUserBeforeLogout();
        this.formIsComplete = true;
        this.showPopup = true;
        this.loading = false;
        console.log(err);
      }
    );
  }

  async loadPaiementForm() {
    this.loading = true;
    this.paiementData.totalAmount =
      this.order.adhesionAmount + this.order.ersAmount;
    this.paiementData.has_ERS = this.adhesionForm.value.ers_paper;
    this.paiementData.is_visible = this.adhesionForm.value.is_visible;
    this.paiementData.pricing_name = this.order.pricing;
    this.paiementData.user_mail = this.user.mail;
    this.paiementData.user_civility = this.user.civility_id;
    this.paiementData.user_firstname = this.user.firstname;
    this.paiementData.user_name = this.user.name;
    this.paiementData.billing_number = this.purchaseService.generateBillNumber(
      "adhesion_physic",
      { name: this.user.name }
    );
    this.paiementData.user_country_code = this.country_code;
    this.paiementData.user_phone = this.user.cellphone;
    this.paiementData.user_address = this.adhesionForm.value.billing_address;
    this.paiementData.user_city = this.adhesionForm.value.billing_city;
    this.paiementData.user_postal_code =
      this.adhesionForm.value.billing_postal_code;
    this.paiementData.user_id = this.user.id;
    this.paiementData.now = timestampToDate(new Date().getTime());

    /* TEST PAIEMENT */
    if (this.adhesionForm.value.hacked_paiement === "cgratuit") {
      this.paiementData.totalAmount = 1;
    }

    if (this.order.ersAmount !== 0 || this.order.adhesionAmount !== 0) {
      // console.log(this.order.adhesionAmount);
      const reference = randomChars(11);
      const urlTempPaiement = apiURL("temp_paiement", "create", "");
      const tempPaiement: any = {};
      tempPaiement.adhesion_amount = this.order.adhesionAmount;
      tempPaiement.billing_address_line_1 = this.paiementData.user_address;
      tempPaiement.billing_address_line_2 = this.paiementData.user_postal_code;
      tempPaiement.billing_address_line_3 = this.paiementData.user_city;
      tempPaiement.billing_number = this.paiementData.billing_number;
      tempPaiement.ers_amount = this.order.ersAmount;
      tempPaiement.ers_pricing = ERS_SUBSCRIPTION_AMOUNT;
      tempPaiement.has_ERS = this.paiementData.has_ERS;
      tempPaiement.is_visible = this.paiementData.is_visible;
      tempPaiement.pricing_name = this.paiementData.pricing_name;
      tempPaiement.promo_code = this.order.promoCode;
      tempPaiement.ref = reference;
      tempPaiement.type = "adhesion_physic";
      tempPaiement.user_address =
        this.adhesionForm.value.delivery_address.replace(",", " ");
      tempPaiement.user_firstname = this.paiementData.user_firstname;
      tempPaiement.user_fullname = this.user.firstname + " " + this.user.name;
      tempPaiement.user_id = this.paiementData.user_id;
      tempPaiement.user_lastname = this.paiementData.user_name;
      tempPaiement.user_mail = this.paiementData.user_mail;
      tempPaiement.user_phone = this.paiementData.user_phone;

      ajax(
        urlTempPaiement,
        "POST",
        tempPaiement,
        (res) => {
          this.moneticoHref = `${GLOBALS[ENV].API_URL}/--monetico/src/Send.php?reference=${reference}&amount=${this.paiementData.totalAmount}&user_mail=${this.paiementData.user_mail}&user_civility=${this.paiementData.user_civility}&user_firstname=${this.paiementData.user_firstname}&user_name=${this.paiementData.user_name}&billing_number=${this.paiementData.billing_number}&user_address=${this.paiementData.user_address}&user_country_code=${this.paiementData.user_country_code}&user_phone=${this.paiementData.user_phone}&city=${this.paiementData.user_city}&postal_code=${this.paiementData.user_postal_code}&user_id=${this.paiementData.user_id}&pricing_name=${this.paiementData.pricing_name}&has_ERS=${this.paiementData.has_ERS}&is_visible=${this.paiementData.is_visible}`;

          window.open(this.moneticoHref, "_blank");
          this.showMoneticoExternalLink = true;
        },
        false
      );
      // this.popupText = `Vous êtes à présent déconnecté(e) ! Une fois le paiement finalisé, lors de votre prochaine connexion, vous aurez le statut d'adhérent(e).`;
      this.setUpUserBeforeLogout();
      this.formIsComplete = true;
      this.loading = false;
    } else {
      const pdfDatas = await this.generatePdfAdhesion(
        this.paiementData.totalAmount,
        this.paiementData.billing_number,
        this.paiementData.now,
        this.paiementData.user_address,
        this.paiementData.user_postal_code,
        this.paiementData.user_city
      );
      this.sendMail(pdfDatas, this.paiementData.totalAmount === 0);
    }
  }

  setUpUserBeforeLogout() {
    updateStoredUser({});
    this.user = new User({});
  }

  generatePdfAdhesion(
    amount,
    billingNumber,
    date_,
    billing_address,
    billing_postal_code,
    billing_city
  ) {
    return new Promise((resolve) => {
      let pdfsData = "";

      const infosFiscal = {
        amount,
        reference: billingNumber,
        userFirstname: this.user.firstname,
        userName: this.user.name,
        signupPrice: this.order.signupPrice,
      };

      const infosAdhesion = {
        pricingName: this.order.pricing,
        adhesionAmount: this.order.adhesionAmount,
        ersAmount: this.order.ersAmount,
        address: [billing_address, billing_postal_code, billing_city],
        billingNumber,
        userName: this.user.firstname + " " + this.user.name,
        datePaiement: date_,
        signupPrice: this.order.signupPrice,
      };

      this.pdfGeneratorService
        .generatePdf("fiscal-adhesion-physique", infosFiscal, "send")
        .getDataUrl((dataUrlFiscal) => {
          pdfsData += `${dataUrlFiscal}`;
          this.pdfGeneratorService
            .generatePdf(
              "facture-adhesion-physique-acquittee",
              infosAdhesion,
              "send"
            )
            .getDataUrl((dataUrlAdhesion) => {
              pdfsData += `__separator__${dataUrlAdhesion}`;
              resolve(pdfsData);
            });
        });
    });
  }

  doSomethingAfterPopupWasClosed() {
    switch (this.popupAction) {
      case "finalizePaiement":
        this.loadPaiementForm();
        this.adhesionPage++;
        break;
      case "navigateToHome":
        this.router.navigate(["/accueil"]);
        break;
      default:
        window.alert("Une erreur est survenue");
    }
    this.popupAction = null;
    this.closePopupAndWindow();
  }

  setThinkSections($event) {
    this.thinkSections = $event;
  }

  checkIfUserHasCheckedAllThinkSections() {
    if (!this.thinkSections) {
      return false;
    }

    if (
      this.thinkSections.filter((item) => item.belongsToUser === "none")
        .length > 0
    ) {
      return false;
    }
    return true;
  }

  updateUserThinkSections() {
    const userThinkSectionsData = {
      think_section_ids: this.thinkSections
        .filter((item) => item.belongsToUser == "1")
        .map((item) => item.id)
        .join(","),
      user_id: this.user.id,
    };
    if (!userThinkSectionsData.think_section_ids) {
      userThinkSectionsData.think_section_ids = "none";
    }
    const userThinksectionUrl = apiURL("user_think_section", "update", "");

    ajax(
      userThinksectionUrl,
      "POST",
      userThinkSectionsData,
      (res: any) => {
        this.popupText =
          "Les sections de réflexion auxquelles vous souhaitez participer ont été mises à jour";
        this.showPopup = true;
      },
      false
    );
  }

  ngOnDestroy() {
    document.body.style.overflow = "auto";
  }

  ngOnInit() {
    document.body.style.overflow = "hidden";
    window.scroll(0, 0);
    this.loadThematics();
    const url = apiURL("user_status", "read", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.statuses = JSON.parse(res).records || [];
      },
      false
    );

    const url2 = apiURL("organization_category", "read", "");
    ajax(
      url2,
      "GET",
      null,
      (res: any) => {
        this.organizationCategories = JSON.parse(res).records;
      },
      false
    );

    const url3 = apiURL("country", "read", "");
    ajax(
      url3,
      "GET",
      null,
      (res: any) => {
        this.countries = JSON.parse(res).records || [];
        if (this.user.country_id) {
          const index = this.countries.findIndex(
            (c) => c.id == this.user.country_id
          );
          this.country_code = this.countries[index].country_code;
        }
      },
      false
    );
    if (this.user.role_id == "3") {
      this.adhesionPage = 3;
    }
  }
}
