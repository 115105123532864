import { Component, OnInit } from "@angular/core";
// import {  } from '../../../providers/global';
import { ajax, apiURL } from "../../../../providers/helpers";
import { getStoredUser } from "../../../../providers/user-service";

@Component({
  selector: "app-my-think-sections-list",
  templateUrl: "./my-think-sections-list.component.html",
  styleUrls: ["./my-think-sections-list.component.css"],
})
export class MyThinkSectionsListComponent implements OnInit {
  //_pdf_name = _PDF_NAME;

  user: any;
  userThinkSections: any;
  pdf_id = "";

  popupText = "";
  showPopup = false;

  constructor() {}

  closePopupAndWindow() {
    this.showPopup = null;
  }

  // showPdf() {
  //   const url = apiURL('pdf', 'read_one_id', '');

  //   ajax(url, 'POST', { name: _PDF_NAME }, res => {
  //     let response: any = res;
  //     response = JSON.parse(response);
  //     this.pdf_id = response.records.id;
  //   }, false);
  // }

  loadUserThinkSections(userId) {
    const url = apiURL("user_think_section", "read_by_user", userId);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.userThinkSections = JSON.parse(res).records.map((item) => {
          if (item.img_data) {
            item.img_data = "url(data:image/jpg;base64," + item.img_data + ")";
          }
          return item;
        });
        // console.log(this.userThinkSections);
      },
      false
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.user = getStoredUser();
    this.loadUserThinkSections(this.user.id);
  }
}
