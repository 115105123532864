import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transition-page',
  templateUrl: './transition-page.component.html',
  styleUrls: ['./transition-page.component.css']
})
export class TransitionPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    this.router.navigate(['espace_perso/accueil']);
  }

}
