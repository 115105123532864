import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-aside-btns",
  templateUrl: "./aside-btns.component.html",
  styleUrls: ["./aside-btns.component.css"],
})
export class AsideBtnsComponent implements OnInit {
  @Input() items: any;
  @Input() is_active: string[];
  @Input() is_closed: string;
  @Input() congress_debut: string;
  @Input() congress_end: string;
  @Input() congress_pricing_adherent_before: string;
  @Input() congress_pricing_adherent_after: string;
  @Input() congress_pricing_user_before: string;
  @Input() congress_pricing_user_after: string;
  @Input() congress_pricing_student: string;
  @Input() congress_pricing_limit_date: string;
  @Input() congress_url: string;

  @Output() output = new EventEmitter();

  constructor() {}

  faArrowRight = faArrowRight;
  linkHasBeenCopied = false;

  popupText =
    "Vous devez être connecté sur le site pour pouvoir vous inscrire au Congrès SFSE. Si vous n’avez pas de compte, créez-en un en 1 minute !";

  emit(pdf_id) {
    this.output.emit(pdf_id);
  }

  copyCongressPageLink($congressIdInput) {
    $congressIdInput.select();
    document.execCommand("copy");
    $congressIdInput.setSelectionRange(0, 0);
    this.displayCopiedLinkMsg();
  }

  displayCopiedLinkMsg() {
    this.linkHasBeenCopied = true;
    window.setTimeout(() => {
      this.linkHasBeenCopied = false;
    }, 4000);
  }

  ngOnInit() {
    // console.log(this.is_closed);
  }
}
