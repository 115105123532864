import { Component, OnInit } from '@angular/core';
import { CongressService } from '../../../providers/congress.service';
// import  Congress  from '../../../models/Congress';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.css']
})
export class AwardsComponent implements OnInit {

  navItem = {icon: 'acts'};
  congress : any = {};
  pdf_id;

  sideNav2;
  user: any = {};


  constructor(
    private congressService: CongressService, 
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    window.scroll(0,0); 
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('prix', data.id).then((data: any) => {
        this.user     = data.user;

        this.congress = data.congress;
        this.sideNav2 = data.sideNav2;  
      }).catch(err => {
        // console.log(err);
      });
    });
  }

}
