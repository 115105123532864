import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PdfGeneratorService } from '../../../providers/pdf-generator.service';
import { apiURL, ajax } from '../../../providers/helpers';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PurchaseService } from 'src/app/providers/purchase.service';


@Component({
  selector: 'app-adhesion-morale-devis-form-popup',
  templateUrl: './adhesion-morale-devis-form-popup.component.html',
  styleUrls: ['./adhesion-morale-devis-form-popup.component.css']
})
export class AdhesionMoraleDevisFormPopupComponent implements OnInit {

  @Output() close = new EventEmitter();

  faTimes = faTimes;
  popupText = "";
  showPopup = false;

  adherent: any = {
    social_status_id: '',
    annual_ca_id: '',
    is_scientific_society: 'non',
    billing_address: '',
    // contact_name: '',
    // contact_mail: '',
    // billing_contact_name: '',
    // billing_contact_phone: '',
    // billing_contact_mail: ''
  };

  social_status_name: string;
  annual_ca_name: string;

  numberOfAdhesionOffered: number;
  numberOfExtraAdhesion = 0;
  adhesionCost: number;
  adhesionAmount: number;

  socialStatuses = [];
  annualCas = [];
  annualCasFiltered = [];

  constructor(
    private pdfGeneratorService: PdfGeneratorService, 
    private purchaseService: PurchaseService
  ) { }

  _close() {
    this.close.emit();
  }

  onSocialStatusChange(social_status) {
    this.adherent.annual_ca_id = null;
    this.adhesionAmount = null;
    this.numberOfAdhesionOffered = null;
    this.numberOfExtraAdhesion = 0;
    this.social_status_name = social_status.name;

    if (social_status.id == 1 && this.adherent.is_scientific_society == 'oui') {
      this.calculateNumberOfAdhesionOffered();
    } else {
      this.adherent.is_scientific_society = 'non';
    }

    if (social_status.id != 3) {
      this.filterAnnualCas(social_status.id);
    } else {
      this.annualCasFiltered = [];
      this.calculateNumberOfAdhesionOffered();
    }

  }

  onIsScientificSocietyChange() {
    this.numberOfExtraAdhesion = 0;
    if (this.adherent.is_scientific_society == 'oui') {
      this.popupText = `En tant que société savante, vous bénéficierez d'un nombre illimité d'adhésions annuelles, à distribuer à des personnes physiques.
      Vous recevrez donc, après inscription, un mail contenant un code promotionnel à usage illimité sur une durée de 1 an, 
      permettant l'adhésion de personnes physiques à la SFSE, à tarif préférentiel (45€).`;
      this.showPopup = true;
      this.calculateNumberOfAdhesionOffered();
    } else {
      this.adherent.annual_ca_id = null;
      this.adhesionAmount = null;
      this.numberOfAdhesionOffered = null;
    }
  }

  requiredFieldsMissing() {
    const requiredFields = [
      "Statut", "Adresse de facturation"
    ];

    const missings = [];
    this.popupText = "Une adresse de facturation doit être renseignée pour qu'un devis puisse être généré.";
    
    ['social_status_id','billing_address'].forEach((field, i) => {
      if(!this.adherent[field]) {
        missings.push(requiredFields[i]);
      }
    });

    return missings;

  }

  onAnnualCaChange(annual_ca_name) {
    this.annual_ca_name = annual_ca_name;

    this.numberOfExtraAdhesion = 0;
    if (this.adherent.is_scientific_society == 'non') {
      this.calculateNumberOfAdhesionOffered();
    }
  }

  onNumberOfExtraAdhesionChange() {
    if (this.numberOfExtraAdhesion < 0 || this.numberOfExtraAdhesion > 12) {
      this.numberOfExtraAdhesion = null;
    }
    this.adhesionAmount = this.purchaseService.addExtraAdhesion(this.numberOfExtraAdhesion);
  }

  filterAnnualCas(evt) {
    if (evt == 1) { 
      this.annualCasFiltered = this.annualCas.filter(ca => ca.id <= 2);
    } else if (evt != 3) {
      this.annualCasFiltered = this.annualCas.filter(ca => ca.id > 2);
    } else {
      this.annualCasFiltered = [];
    }
  }

  calculateNumberOfAdhesionOffered() {
    if (this.adherent.is_scientific_society == 'oui') {
      this.numberOfAdhesionOffered = 1;
    } else if (this.adherent.social_status_id == 1) {
      if (this.adherent.annual_ca_id == 1) {
        this.numberOfAdhesionOffered = 2
      } else if (this.adherent.annual_ca_id == 2) {
        this.numberOfAdhesionOffered = 3;
      } else { return; }
    } else if (this.adherent.social_status_id == 2) {
      if (this.adherent.annual_ca_id == 3) {
        this.numberOfAdhesionOffered = 2
      } else if (this.adherent.annual_ca_id == 4) {
        this.numberOfAdhesionOffered = 3;
      } else { alert('Une erreur est survenue'); }
    } else if (this.adherent.social_status_id == 3) {
      this.numberOfAdhesionOffered = 3;
    } else if (this.adherent.social_status_id == 4) {
      this.numberOfAdhesionOffered = 1;
    } else { 
      // console.log(this.adherent.social_status_id);
      alert('Une erreur est survenue'); 
    }

    this.purchaseService.calculateMoralAdhesionAmount(
      this.adherent.social_status_id,
      this.adherent.annual_ca_id,
      this.numberOfExtraAdhesion,
      null,
      this.adherent.is_scientific_society).then((response: any) => {
        this.adhesionAmount = response.total;
        this.adhesionCost = response.adhesionCost;
      });
  }


  generatePdfDevisAdhesionMorale() {
    const missingFiedls = this.requiredFieldsMissing();
    if(missingFiedls.length > 0) {
      this.showPopup = true;
      return;
    }
    const infos: any = {
      annual_ca_name: this.annual_ca_name,
      social_status_name: this.social_status_name,
      adherent: this.adherent,
      pricings: {
        adhesionCost: this.adhesionCost,
        adhesionAmount: this.adhesionAmount,
        numberOfAdhesionOffered: this.numberOfAdhesionOffered,
        numberOfExtraAdhesion: this.numberOfExtraAdhesion
      },
      devisNumber: new Date().getFullYear() + '-' + new Date().getTime()
    };
    // console.log(infos);
    this.pdfGeneratorService.generatePdf('devis-adhesion-morale', infos);
  }

  ngOnDestroy() {
    document.body.style.overflow = "auto";
  }

  ngOnInit() {
    document.body.style.overflow = "hidden";
    window.scroll(0,0);
    const socialStatusUrl = apiURL('social_status', 'lire', '');

    ajax(socialStatusUrl, 'GET', null, res => {
      let response: any = res;
      response = JSON.parse(response);
      this.socialStatuses = response.records;
    }, false);

    const annualCaUrl = apiURL('annual_ca', 'lire', '');

    ajax(annualCaUrl, 'GET', null, res => {
      let response: any = res;
      response = JSON.parse(response);
      this.annualCas = response.records;
    }, false);
  }

}
