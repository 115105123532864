import { Component, OnInit } from '@angular/core';
import { faCopyright, faEnvelope, faRss, faSearch } from '@fortawesome/free-solid-svg-icons';
import { NavData } from '../../../providers/NavData';
import { Router } from '@angular/router';
import { getStoredUser } from '../../../providers/user-service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  faEnvelope  = faEnvelope;
  faRss       = faRss;
  faSearch    = faSearch;
  faCopyright = faCopyright;

  nav = [];

  popupText = "";
  showPopup = false;

  goTo(url, visible_by) {
    if(visible_by) {
      const user =  getStoredUser();
      if(visible_by === "user" && !user.id) {
        this.popupText = "Section uniquement accessible aux utilisateurs inscrits à la SFSE";
        this.showPopup = true;
        return;
      }
      if(visible_by === "adherent" && user.role_id != 3) {
        this.popupText = "Section uniquement accessible aux adhérents SFSE";
        this.showPopup = true;
        return;
      }
    }
    window.scroll(0,0);
    this.router.navigate([url]);
  }

  goToThematics(){
    window.scroll(0,0);
    this.router.navigate(['/rechercher_par_thematique']);
    
  }

  closePopupAndWindow() {
    this.showPopup = null;
  }
  constructor(private router: Router) { }

  ngOnInit() {
    this.nav = NavData.data;
  }

}
