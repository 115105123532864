import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  @Input() text : string;
  @Input() action : any;
  @Output() output = new EventEmitter();
  @Output() actionOutput = new EventEmitter();
  @Output() mouseEntered = new EventEmitter();

  constructor() { }

  close() {
    if (!this.action){
      this.output.emit();
    }
    else {
      this.actionOutput.emit();
    }
  }

  onMouseEnter() {
    this.mouseEntered.emit();
  }

  ngOnInit() {}

}
