import { Component, OnInit } from '@angular/core';
import { apiURL, ajax } from '../../providers/helpers';

@Component({
  selector: 'app-thematic-search',
  templateUrl: './thematic-search.component.html',
  styleUrls: ['./thematic-search.component.css']
})
export class ThematicSearchComponent implements OnInit {
  images = [
    {
      src: '../../../assets/thematiques-images/alimentation.jpg',
      alt: 'Thématique Alimentation'
    },
    {
      src: '../../../assets/thematiques-images/sitessolspollues.jpg',
      alt: 'Sites et sols pollués'
    },
    {
      src: '../../../assets/thematiques-images/climat-energie.jpg',
      alt: 'Climat énergies'
    },
    {
      src: '../../../assets/thematiques-images/environnement-sante.jpg',
      alt: 'Environnement et santé'
    },
    {
      src: '../../../assets/thematiques-images/urbanisme-sante.jpg',
      alt: 'Urbanisme santé'
    },
    {
      src: '../../../assets/thematiques-images/environnements-interieurs.jpg',
      alt: 'Environnements intérieurs'
    },
    {
      src: '../../../assets/thematiques-images/qualite-eau.jpg',
      alt: 'Qualité de l\'eau'
    },
    {
      src: '../../../assets/thematiques-images/produits-substances-chimiques.jpg',
      alt: 'Produits/substances chimiques'
    },
    {
      src: '../../../assets/thematiques-images/perturbateurs-endocriniens.jpg',
      alt: 'Perturbateurs endocriniens'
    },
    {
      src: '../../../assets/thematiques-images/agents-physique.jpg',
      alt: 'Agents physiques'
    },
    {
      src: '../../../assets/thematiques-images/agents-biologiques.jpg',
      alt: 'Agents biologiques'
    },
    {
      src: '../../../assets/thematiques-images/multi-expositions.jpg',
      alt: 'Multi-expositions'
    },
    {
      src: '../../../assets/thematiques-images/expositions-professionnelles.jpg',
      alt: 'Expositions professionnelles'
    },
    {
      src: '../../../assets/thematiques-images/effets-sur-la-sante.jpg',
      alt: 'Effets sur la santé'
    },
    {
      src: '../../../assets/thematiques-images/inegalites-environnementales.jpg',
      alt: 'Inégalités environnementales'
    },
    {
      src: '../../../assets/thematiques-images/biodiversite.jpg',
      alt: 'Biodiversité'
    },
    {
      src: '../../../assets/thematiques-images/hygiene.jpg',
      alt: 'Hygiène / accès aux soins'
    },
    {
      src: '../../../assets/thematiques-images/developpement-durable.jpg',
      alt: 'Développement durable'
    },
    {
      src: '../../../assets/thematiques-images/methodologie-evaluation.jpg',
      alt: 'Méthodologie d\'évaluation'
    },
    {
      src: '../../../assets/thematiques-images/economie-circulaire.jpg',
      alt: 'Economie circulaire'
    },
    {
      src: '../../../assets/thematiques-images/sante-environnement-general.jpeg',
      alt: 'Santé-Environnement (Général)',
      isBigger: true
    },
  ]
  constructor() { }

  thematics = <any>[];
  ngOnInit() {
    window.scroll(0,0);
    const url = apiURL('thematic', 'read_with_img', null);
    ajax(url, 'GET', null, (res: any) => {
      this.thematics = JSON.parse(res).records.map(thematic => {
        thematic.img_src = thematic.img_data ? `url("data:image/jpg;base64,${thematic.img_data}")` : 'url("../../../assets/sfse-logo-square.png")';
        return thematic;
      });
      // console.log(this.thematics);
    }, false);
  }

}
