import { Component, OnInit } from '@angular/core';
import { CongressService } from '../../../providers/congress.service';
// import Congress from '../../../models/Congress';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class PresentationComponent implements OnInit {

  congress: any = {};
  sideNav2 = null;
  pdf_id;
  user: any   = {};
  
  constructor(
    private congressService: CongressService, 
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    window.scroll(0,0);
    this.activatedRoute.params.subscribe(data => {
      this.congressService.init('presentation', data.id).then((data: any) => {
        this.user     = data.user || {};
        this.congress = data.congress || {};
        // console.log(this.congress);
        this.sideNav2 = data.sideNav2 || false;  
      }).catch(err => {
        // console.log(err);
      });
    });
  }
}
