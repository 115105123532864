import { Component, OnInit } from '@angular/core';
import { NavItem } from '../../../models/NavItem';
import { NavData } from '../../../providers/NavData';

@Component({
  selector: 'app-actions-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class ActionsMainComponent implements OnInit {

  navItems : NavItem[];
  
  constructor() { }

  ngOnInit() {
    window.scroll(0,0); 
    this.navItems = NavData.getAsideNavItems(2).sideNav;
  }

}