import { Component, EventEmitter, Output, Input, ViewChild, OnChanges } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


import {ajax, apiURL} from '../../../providers/helpers';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnChanges {
  @ViewChild('pdfViewerOnDemand', {static:true}) public pdfViewerOnDemand;

  @Input() pdf_id: string; 
  @Output() output = new EventEmitter();

  faTimes = faTimes;
  isClosed;

  displayPdfDetail(id) {
    const imgUrl = apiURL('pdf', 'read_one', id);
    ajax(imgUrl, 'GET', null, (res: string) => {
     
      res = JSON.parse(res);
      // decode base64 string, remove space for IE compatibility
      const binary = atob(res['data'].replace(/\s/g, ''));
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      this.pdfViewerOnDemand.pdfSrc = new Uint8Array(buffer).map((item, i) => binary.charCodeAt(i));
      this.pdfViewerOnDemand.downloadFileName = res['name'];
      this.pdfViewerOnDemand.refresh();
    }, false);
  }

  closePdfViewer() {
    this.output.emit();
  }

  ngOnChanges() {
    this.displayPdfDetail(this.pdf_id);
  }

}
