import { Component, OnInit } from '@angular/core';

import { apiURL, ajax } from '../../../providers/helpers';
import { STATUS_PDF_NAME, PLAQUETTE_PRESENTATION_PDF_NAME, REGLEMENT_INTERIEUR_PDF_NAME } from '../../../providers/global';

@Component({
  selector: 'app-quisommesnous',
  templateUrl: './quisommesnous.component.html',
  styleUrls: ['./quisommesnous.component.css']
})
export class QuisommesnousComponent implements OnInit {

  status_pdf_name = STATUS_PDF_NAME;
  plaquette_presentation_pdf_name = PLAQUETTE_PRESENTATION_PDF_NAME;
  reglement_interieur_pdf_name = REGLEMENT_INTERIEUR_PDF_NAME;
  
  pdf_id;
  
    
  constructor() { }


  showPdf(pdfName) {
    const url = apiURL('pdf', 'read_one_id', '');

    ajax(url, 'POST', {name: pdfName}, res => {
      let response: any = res;
      (response);
      response = JSON.parse(response);
      this.pdf_id = response.records.id;
    }, false);
  }
  

  ngOnInit() {
    window.scroll(0,0);
  }
  

}
