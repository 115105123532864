import { Component, Input } from '@angular/core';
import { faClone } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-congress-link-btn',
  templateUrl: './congress-link-btn.component.html',
  styleUrls: ['./congress-link-btn.component.css']
})
export class CongressLinkBtnComponent {
  @Input() congress_url: string;
  @Input() right: boolean;

  constructor() { }

  faClone = faClone;
  linkHasBeenCopied = false;


  copyCongressPageLink($congressIdInput){
    $congressIdInput.select();
    document.execCommand('copy');
    $congressIdInput.setSelectionRange(0, 0);
    this.displayCopiedLinkMsg();
  }

  displayCopiedLinkMsg() {
    this.linkHasBeenCopied = true;
    window.setTimeout(() => {
      this.linkHasBeenCopied = false;
    }, 4000);
  }
}
