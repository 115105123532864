import { Injectable } from '@angular/core';
import { apiURL, ajax } from './helpers';
import { getStoredUser } from './user-service';
import Storage from '../models/Storage';
import User from '../models/User';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class CongressService {
  storage = new Storage();
  user: User;

  constructor(private router: Router) { }
  
  loadCongress(id) {
    return new Promise((resolve, reject) => {
      const url = apiURL('congress', 'read_one', id);
      ajax(url, 'GET', null, res => {
        const response = JSON.parse(res);

        if (!response.records) { 
          reject({
            error: true, 
            msg: `Une erreur est survenue dans le chargement du congrès ayant pour id ${id}`
          });
          return; 
        }
        let data = {congress: null, sideNav2: false};
        data.congress = response.records;
        data.congress.img_data = data.congress.img_data ? `url("data:image/jpg;base64,${data.congress.img_data}")` : 'url("../../../../assets/test/event-2.jpg")';
        data.congress.img_data2 = data.congress.img_data2 ? 'data:image/jpg;base64,' + data.congress.img_data2 : '../../../../assets/test/event-1.jpg';
        this.storage.setItem('congress', data.congress);
        if (data.congress.is_active == "0") {
          data.sideNav2 = true;
        }
        resolve(data);
      }, false, err => { reject(err); });
    });
  }

  getActive() {
    return new Promise((resolve, reject) => {
      const url = apiURL('congress', 'read_one', '');
      fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ is_active: 1 })
      }).then(rawResponse => {
        rawResponse.json().then(res => {
          resolve(res);
        });
      }).catch(err => {
        reject(err);
      });
    });
  }

  redirectToPastCongresses() {
    this.router.navigate(['/congres/list']);
  }
    
  init(url_param, congressId) {
    
    return new Promise(async (resolve, reject) => {
      try {
        let data: any = {};
        this.storage = new Storage();
        data.user = getStoredUser();
        if (congressId == 'null') {
          data.congress = this.storage.getItem('congress');
          if (!data.congress) {
            const res: any = await this.getActive();
            if (!res.records) {
              this.redirectToPastCongresses();
              resolve('past_congress');
            }
  
            data.congress = res.records;
            data.congress.url = `https://www.sfse.org/congres/${url_param}/${data.congress.id}`;
            data.congress.img_data  =  data.congress.img_data ? `url(data:image/jpg;base64,${data.congress.img_data})` : `url("../../../../assets/test/event-2.jpg")`;
            data.congress.img_data2 =  data.congress.img_data2 ? 'data:image/jpg;base64,' + data.congress.img_data2 : '';
            this.storage.setItem('congress', data.congress);
            if(data.congress.is_active == "0") {
              data.sideNav2 = true;
            }
            resolve(data);
          } else {
            data.congress.url = `https://www.sfse.org/congres/${url_param}/${data.congress.id}`;
            if(data.congress.is_active == "0") {
              data.sideNav2 = true;
            }
            resolve(data);
          }
        } else {
          data = await this.loadCongress(congressId);
          data.user = getStoredUser();
          if(data.congress.is_active == "0") {
            data.sideNav2 = true;
          }
          data.congress.url = `https://www.sfse.org/congres/${url_param}/${congressId}`;
          resolve(data);
        } 
      } catch(error) {
        reject(error);
      }
    });
  }
}
