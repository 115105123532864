import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faSync, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-select-field",
  templateUrl: "./select-field.component.html",
  styleUrls: ["./select-field.component.css"],
})
export class SelectFieldComponent implements OnInit {
  @Input() field: any;
  @Output() output = new EventEmitter<string>();
  @Input() item: any;

  faSync = faSync;
  faTimes = faTimes;
  img_data: String;

  initList(tableName, id, isFolder) {
    const method = this.field.method || "lire";
    const url = apiURL(tableName, method, id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        res = JSON.parse(res);
        if (isFolder) {
          this.field.folderOptions = res.records;
        } else {
          this.field.options = res.records;
        }
        /*
      ['options', 'folderOptions'].forEach(prop => {
        
        const currentValue = this.item[this.field.model];
        const fieldOptions = this.field[prop];

        if(fieldOptions) {
          (fieldOptions, this.item, tableName+'_id');
          const index = fieldOptions.findIndex(option => option.id == currentValue);
          (fieldOptions, index, this.item);
          if(index > -1) {
            this[prop+'_currentValue'] = fieldOptions[index].name;
            (fieldOptions[index].name)
          }
        }
      });
      */
      },
      false
    );
  }

  setNull(model) {
    this.resetImg(model);
    this.emit(model);
  }

  resetImg(model) {
    this.item[model] = null;
  }

  emit(model) {
    this.output.emit(this.item[model]);
  }

  constructor() {}

  ngOnInit() {
    if (!this.item) {
      this.item = {};
    }
    if (this.item.img_data) {
      this.img_data = "data:image/jpg;base64," + this.item.img_data;
    }

    if (this.item.img_data2) {
      this.item.img_data2 = "data:image/jpg;base64," + this.item.img_data2;
    }

    if (this.field.countryId) {
      this.initList(this.field.table_name, this.field.countryId, false);
    } else if (this.field.type === "select") {
      this.field;
      this.initList(this.field.table_name, null, false);
    } else {
      this.initList(this.field.folder_table_name, null, true);
    }
  }
}
