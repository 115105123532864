import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { countriesWithDialCodes } from "../../../../assets/country-dials.min.js";

@Component({
  selector: "app-phone-field",
  templateUrl: "./phone-field.component.html",
  styleUrls: ["./phone-field.component.css"],
})
export class PhoneField implements OnInit {
  @Input() className: any;
  @Input() initialPhoneNumber: string;

  @Output() output = new EventEmitter<string>();

  faCaretDown = faCaretDown;
  countriesWithDialCodes = countriesWithDialCodes;
  showCountriesList = false;
  selectedCountry = this.countriesWithDialCodes[72];
  phoneNumber = "";

  constructor() {}

  onPhoneNumberChanged() {
    this.showCountriesList = false;
    this.emitPhone();
  }

  selectCountry(index) {
    this.selectedCountry = this.countriesWithDialCodes[index];
    this.showCountriesList = false;
    this.emitPhone();
  }

  emitPhone() {
    this.output.emit(this.selectedCountry.code + "|" + this.phoneNumber);
  }

  ngOnInit() {
    if (this.initialPhoneNumber) {
      const [code, phone] = this.initialPhoneNumber.split("|");
      if (phone) {
        this.phoneNumber = phone;
        this.selectedCountry = this.countriesWithDialCodes.find(
          (country) => country.code === code
        );
      }
    }
  }
}
